import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Header, Home, Details, Order, NotFound, SecureRoute } from './components';
import { ManageLookups, Profile } from './components/Admin';
import { Add, Edit } from './components/Admin';
import config from './config';
import GlobalProvider from './contexts';
import { ScrollToTop } from './util';

const App = () => {
  const oktaAuth = new OktaAuth(config.oidc);
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) =>
    navigate(toRelativeUrl(originalUri ?? '/', window.location.origin));

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <GlobalProvider>
        <Header />
        <div className="m-2 lg:m-5 text-gray-900">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<Home />} />
            <Route path="/citation/:id" element={<Details />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="*" element={<NotFound />} />

            {/* Auth-protected routes must be placed under this route */}
            <Route path="" element={<SecureRoute />}>
              <Route path="/citation/edit/:id" element={<Edit />} />
              <Route path="/citation/add" element={<Add />} />
              <Route path="/order" element={<Order />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/manage_lookups" element={<ManageLookups />} />
            </Route>
          </Routes>
        </div>
      </GlobalProvider>
    </Security>
  );
};

export default App;
