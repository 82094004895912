import React, { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import logo from '../assets/images/logo.svg';
import { globalContext } from '../contexts';

const Header = () => {
  const { authState, oktaAuth } = useOktaAuth();

  const signIn = async () => oktaAuth.signInWithRedirect();
  const signOut = async () => await oktaAuth.signOut();

  const { order, user } = globalContext();
  const { pathname } = useLocation();

  return (
    <nav className="flex items-center mx-auto justify-between space-x-10 px-2.5 md:px-6 border-b-2 border-gray-100">
      <div className="flex justify-start items-center gap-4 lg:w-0 lg:flex-1">
        <NavLink to="/" className={''}>
          <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
        </NavLink>
        <NavLink to="/" className="shrink-0 sm:border-l-2 sm:pl-4">
          <h1 className="hidden text-primary hover:text-primary-3 lg:block">
            Patient and Family Education Database
          </h1>
        </NavLink>
      </div>

      <div className="flex lg:w-0 lg:flex-1 justify-end items-center">
        {authState && authState.isAuthenticated ? (
          <>
            <NavLink
              to={`/search`}
              className={`${
                ['/', '/search'].includes(pathname)
                  ? 'border-b-primary text-primary hover:text-primary-3'
                  : 'border-b-transparent text-gray-400 hover:text-primary'
              } hidden md:block text-sm mx-2 py-5 md:py-6 whitespace-nowrap border-y-2 border-t-transparent transition-colors`}
            >
              Search
            </NavLink>
            <NavLink
              to={`/order`}
              className={({ isActive }) =>
                `${
                  isActive
                    ? 'border-b-primary text-primary hover:text-primary-3'
                    : 'border-b-transparent text-gray-400 hover:text-primary'
                } hidden md:block text-sm mx-2 py-5 md:py-6 whitespace-nowrap border-y-2 border-t-transparent transition-colors`
              }
            >
              Order ({order.length})
            </NavLink>

            {/* Admin Tools */}
            {user?.admin && (
              <Menu as="div" className="relative inline-block text-left mx-2">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button
                        className={`${
                          ['/citation/add', '/manage_lookups'].includes(pathname)
                            ? 'border-b-primary text-primary hover:text-primary-3'
                            : open
                            ? 'text-primary border-b-transparent'
                            : 'border-b-transparent text-gray-400 hover:text-primary'
                        } hidden md:inline-flex text-sm py-5 md:py-6 border-y-2 border-t-transparent transition-colors justify-center w-full`}
                      >
                        Admin Tools
                        <ChevronDownIcon
                          className={`-mr-1 ml-1 h-5 w-5 transition-transform ${
                            open && 'rotate-180'
                          }`}
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to={`/citation/add`}
                                className={`block px-4 py-2 text-sm ${
                                  active ? 'bg-blue-50 text-gray-900' : 'text-gray-700'
                                }`}
                              >
                                Add Resource
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to={`/manage_lookups`}
                                className={`block px-4 py-2 text-sm ${
                                  active ? 'bg-blue-50 text-gray-900' : 'text-gray-700'
                                }`}
                              >
                                Manage Lookups
                              </NavLink>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="https://tableau.childrens.sea.kids/#/projects/454"
                                target="_blank"
                                rel="noreferrer"
                                className={`block px-4 py-2 text-sm ${
                                  active ? 'bg-blue-50 text-gray-900' : 'text-gray-700'
                                }`}
                              >
                                Tableau Dashboard
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            )}
            <span
              className="cursor-pointer mx-2 text-sm py-5 md:py-6 whitespace-nowrap text-primary hover:text-primary-3 border-y-2 border-transparent transition-colors"
              onClick={signOut}
            >
              Sign Out
            </span>
          </>
        ) : (
          <span
            className="cursor-pointer mx-2 text-sm py-5 md:py-6 whitespace-nowrap text-primary hover:text-primary-3 border-y-2 border-transparent transition-colors"
            onClick={signIn}
          >
            Sign In
          </span>
        )}
      </div>
    </nav>
  );
};

export default Header;
