import React from 'react';

/**
 * Filled button
 * @param {string} text - Button text
 * @param {function} onButtonClick - onClick
 * @param {boolean} secondary - Use secondary (warning) colors
 */
export default function ButtonFill({ text, onButtonClick, secondary, disabled, className }) {
  const buttonColor = disabled
    ? 'bg-gray-100 text-gray-300'
    : secondary
    ? 'border-secondary bg-secondary text-white hover:text-secondary hover:bg-white'
    : 'border-primary bg-primary text-white hover:text-primary hover:bg-white';
  const buttonClass = `px-4 py-2 text-sm rounded-lg border-2 ${buttonColor} ${className}`;
  return (
    <button className={buttonClass} onClick={onButtonClick} disabled={disabled || false}>
      {text}
    </button>
  );
}
