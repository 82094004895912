import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="mx-auto my-12 text-center text-gray-500">
      <h1 className="text-lg font-medium leading-loose">Sorry! That page was not found.</h1>
      <Link to="/" className="hover:text-primary">
        Click to return home.
      </Link>
    </div>
  );
};

export default NotFound;
