import React from 'react';
import Creatable from 'react-select/creatable';
import { TrashIcon } from '@heroicons/react/outline';
import ButtonOutline from '../common/ButtonOutline';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';

const initLanguageState = {
  lang: '',
  ksbgNumber: '',
  revisionDate: '',
  url: '',
};

function Languages({ borderColor, revisionDates, values }, ref) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'languages',
  });

  // Get latest revisions
  let latestRevisions = {};
  if (revisionDates) {
    for (const revision of revisionDates) {
      if (revision.lang) {
        if (!Object.hasOwn(latestRevisions, revision.lang)) {
          latestRevisions[revision.lang] = revision.revisionDate;
        } else if (new Date(revision.revisionDate) > new Date(latestRevisions[revision.lang])) {
          latestRevisions[revision.lang] = revision.revisionDate;
        }
      }
    }
  }

  return (
    <div className="flex flex-col" ref={ref}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block sm:px-6 lg:px-8">
          <div
            className={`shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ${borderColor}`}
          >
            <table className="table-auto divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Language <span className="text-red-500">*</span>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Children&apos;s Item Number
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Revision Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    URL
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Remove</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {fields.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td className="px-6 py-6 whitespace-nowrap w-1/5">
                        <Controller
                          control={control}
                          name={`languages.${index}.lang`}
                          rules={{ required: true }}
                          render={({ field: { onChange, onBlur, value, name } }) => (
                            <Creatable
                              className={`rounded w-64 shrink-0 ${
                                errors.languages &&
                                errors.languages[index] &&
                                'border border-secondary'
                              }`}
                              menuPortalTarget={document.body}
                              styles={customStyles}
                              options={values}
                              name={name}
                              value={value && { label: value, value: value }}
                              onBlur={onBlur}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                        />
                      </td>
                      <td className="px-6 py-6 whitespace-nowrap">
                        <input
                          className="text-gray-900 border rounded p-2"
                          {...register(`languages.${index}.ksbgNumber`)}
                        />
                      </td>
                      <td className="px-6 py-6 whitespace-nowrap">
                        <Controller
                          control={control}
                          name={`languages.${index}.revisionDate`}
                          rules={{}}
                          render={({ field: { onChange, onBlur, value, name } }) => (
                            <input
                              className="text-gray-900 border border-gray-200 rounded p-2"
                              type="date"
                              name={name}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value || latestRevisions[item.lang]?.split('T')[0] || ''}
                            />
                          )}
                        />
                      </td>
                      <td className="px-6 py-6 whitespace-nowrap">
                        <input
                          className="text-gray-900 border rounded p-2"
                          {...register(`languages.${index}.url`)}
                        />
                      </td>
                      <td className="px-6 py-6 whitespace-nowrap text-sm font-medium">
                        <TrashIcon
                          className="h-6 self-center text-secondary cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            remove(index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-6">
            <ButtonOutline
              text="+ Add New"
              onButtonClick={(e) => {
                e.preventDefault();
                append({ initLanguageState }, { focusName: `languages.${fields.length}.lang` });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Define custom styles for select components.
 */
const customStyles = {
  input: (provided) => ({
    ...provided,
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 5 }),
  // border-secondary if error, border-gray-300 otherwise
  control: (provided, state) => ({
    ...provided,
    border: state.selectProps.error ? '1px solid #db5a1d' : '1px solid #d1d5db',
  }),
};

export default React.forwardRef(Languages);
