import React from 'react';
import Form from './Form';

/**
 * Wrapper to allow route to refresh
 */
const Add = () => {
  return <Form />;
};

export default Add;
