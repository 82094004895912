import React from 'react';
import { XCircleIcon } from '@heroicons/react/outline';

export default function Chip({ filter, onClear }) {
  const text = Object.values(filter)[0];
  return (
    <div className="inline-flex items-center gap-2 px-3 py-1 border-gray-700 text-gray-700 text-sm border rounded-3xl">
      <span>{text}</span>
      <XCircleIcon
        className="w-5 h-5 cursor-pointer hover:stroke-secondary transition-colors"
        onClick={() => onClear(filter)}
      />
    </div>
  );
}
