import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import config from '../config';

const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

export const buildParams = (params) =>
  Object.keys(params).reduce(
    (prev, key) =>
      `${prev ? prev + '&' : '?'}${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    undefined
  );

export const extractLinks = (text) => {
  return text.match(urlRegex) ?? [];
};

export const formatDate = (timestamp) => {
  if (!timestamp) return '';
  return new Date(timestamp?.replace('Z', '')).toLocaleDateString('en-US');
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

/**
 * Recursive function to strip empty values, mutates object
 */
export const removeEmptyOrNull = (obj) => {
  Object.entries(obj).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      removeEmptyOrNull(v);
    }
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v === ''
    ) {
      if (Array.isArray(obj)) {
        obj.splice(k, 1);
      } else {
        delete obj[k];
      }
    }
  });
};

/**
 * Utility to force the browser to download a blob as a file.
 * @param {*} file the file to download
 * @param {*} filename the filename to download as
 */
export const downloadFile = (file, filename) => {
  downloadBlob(new Blob([file]), filename);
};

export const downloadBlob = (blob, filename) => {
  // file url provided by the browser
  const url = window.URL.createObjectURL(blob);
  // html link <a />
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  // add link to document body
  document.body.appendChild(link);
  // click on link to instantiate download and cleanup
  link.click();
  link.parentNode.removeChild(link);
};

export const getQRRedirectUrl = (citationId, url) => {
  return `${config.appUi}/qrredirect.html?id=${citationId}&url=${encodeURIComponent(url)}`;
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const languageSort = (a, b) => {
  if (!a.lang) return 1;
  if (!b.lang) return -1;
  if (a.lang === b.lang) return 0;
  if (a.lang.toLowerCase() === 'english') return -1;
  if (b.lang.toLowerCase() === 'english') return 1;
  if (a.lang > b.lang) return 1;
  return -1;
};

export const languageStrSort = (a, b) => {
  if (a === b) return 0;
  if (a.toLowerCase() === 'english') return -1;
  if (b.toLowerCase() === 'english') return 1;
  if (a > b) return 1;
  return -1;
};
