import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { FacetLabels } from '../../data/constants';

export default function Sidebar(props) {
  const { facets, selectedFilters, handleFilterSelect, showObsolete, setShowObsolete, callback } =
    props;
  let filters = [];
  for (const [facet, value] of Object.entries(facets)) {
    if (value.buckets.length > 0 && !['folder', 'paper_colors'].includes(facet)) {
      filters.push({
        id: facet,
        name: FacetLabels[facet],
        options: value.buckets,
      });
    }
  }

  return (
    <div>
      <main className="mx-auto">
        <div className="pb-6 border-b border-gray-200">
          <h1 className="text-xl text-gray-900">Filtering Options</h1>
        </div>
        <section className="pt-4 md:pt-6 md:pb-24">
          {/* Filters */}
          <form className="block">
            <div className="flex items-center pb-2">
              <input
                id="show-obsolete"
                name="show-obsolete"
                type="checkbox"
                checked={showObsolete}
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                onChange={() => {
                  const showObs = !showObsolete;
                  setShowObsolete(showObs);
                  callback(showObs);
                }}
              />
              <label
                htmlFor="show-obsolete"
                className="ml-3 text-sm text-gray-600 hover:text-primary-3"
              >
                Show obsolete
              </label>
            </div>
            {filters.map((section) => (
              <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                {({ open }) => (
                  <>
                    <h3 className="-my-3 flow-root">
                      <Disclosure.Button className="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">{section.name}</span>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="space-y-4">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={section.id}
                              defaultValue={option.value}
                              type="checkbox"
                              checked={selectedFilters.some(
                                (selected) =>
                                  JSON.stringify(selected) ==
                                  JSON.stringify({ [section.id]: option.value })
                              )}
                              className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                              onChange={() => handleFilterSelect({ [section.id]: option.value })}
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 text-sm text-gray-600 hover:text-primary-3"
                            >
                              {option.value} ({option.count})
                            </label>
                          </div>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </form>
        </section>
        {/* For debugging:
        <div>
          <h1>Filter state:</h1>
          <pre>{JSON.stringify(selectedFilters, null, 2)}</pre>
        </div> */}
      </main>
    </div>
  );
}
