import React from 'react';

/**
 * Generates label and input for edit form
 */
const Input = ({ name, type, label, className, required, grow, ...rest }, ref) => {
  return (
    <div className={`flex items-center ${grow ? 'flex-grow' : ''}`}>
      {label && (
        <div className="w-40">
          <label htmlFor={name}>{label}</label>
          {required && <span className="pl-1 text-secondary-1">*</span>}
        </div>
      )}

      <input
        type={type}
        ref={ref}
        id={name}
        name={name}
        autoComplete="off"
        className={`rounded border w-112 shrink-0 h-9 ${className}`}
        required
        {...rest}
      />
    </div>
  );
};

export default React.forwardRef(Input);
