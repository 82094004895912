import config from '../config';

const buildFilterQuery = (filters) => {
  const filterArray = filters.map((filter) => {
    for (const [key, value] of Object.entries(filter)) {
      return `${key}: '${value.replace(/'/g, "\\'")}'`;
    }
  });
  // Escape single apostrophes
  return encodeURIComponent(`(and ${filterArray.join(' ')})`).replace(/'/g, '%27');
};

export const search = async (searchConfig) => {
  const { value, start, filters, token, noBoost, obsolete } = searchConfig;
  let response = [];
  try {
    const fetchConfig = token ? { headers: { Authorization: `Bearer ${token}` } } : undefined;
    const encodedValue = fixedEncodeURIComponent(value.replace(/'/g, '%27'));
    if ((filters || []).length > 0) {
      const filterQuery = buildFilterQuery(filters);
      const queryResponse = await fetch(
        `${config.appApi}/search?q=${encodedValue}&fq=${filterQuery}&start=${start || 0}&no_boost=${
          noBoost || false
        }&obsolete=${obsolete || false}`,
        fetchConfig
      );
      response = await queryResponse.json();
    } else {
      const queryResponse = await fetch(
        `${config.appApi}/search?q=${encodedValue}&start=${start || 0}&no_boost=${
          noBoost || false
        }&obsolete=${obsolete || false}`,
        fetchConfig
      );
      response = await queryResponse.json();
    }
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getCitation = async (id) => {
  let response;
  let redirect;
  try {
    const queryResponse = await fetch(`${config.appApi}/citations/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (queryResponse.redirected) {
      const redirectParts = queryResponse.url.split('/');
      redirect = `/citation/${redirectParts[redirectParts.length - 1]}`;
    } else {
      response = await queryResponse.json();
    }
  } catch (error) {
    console.error(error);
  }
  return [response, redirect];
};

export const getFacets = async (token) => {
  let facets;
  try {
    const fetchConfig = token ? { headers: { Authorization: `Bearer ${token}` } } : undefined;
    const queryResponse = await fetch(`${config.appApi}/search/facets?updated=true`, fetchConfig);
    const response = await queryResponse.json();
    facets = Object.keys(response).reduce((prev, curr) => {
      prev[curr] = response[curr].buckets.sort((a, b) =>
        a.value.toUpperCase() > b.value.toUpperCase() ? 1 : -1
      );
      return prev;
    }, {});
  } catch (error) {
    console.error(error);
  }
  return facets;
};

export const getSuggestions = async (value) => {
  let response = [];
  try {
    const encodedValue = fixedEncodeURIComponent(value.replace(/'/g, '%27'));
    const queryResponse = await fetch(`${config.appApi}/search/suggestions?q=${encodedValue}`);
    response = await queryResponse.json();
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getUser = async (token) => {
  let response;
  try {
    if (token) {
      const queryResponse = await fetch(`${config.appApi}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      response = await queryResponse.json();
    }
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateCitation = async (id, token, citation) => {
  let response;
  try {
    if (token) {
      const requestOptions = {
        method: 'PUT',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(citation),
      };
      const queryResponse = await fetch(`${config.appApi}/citations/${id}`, requestOptions);
      response = await queryResponse.json();
    }
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const postDepartment = async (token, departmentName, contact) => {
  let response;
  try {
    if (token) {
      const requestOptions = {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: departmentName, frcContact: contact }),
      };
      const queryResponse = await fetch(`${config.appApi}/system/departments`, requestOptions);
      response = await queryResponse.json();
    }
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const addCitation = async (token, citation) => {
  let response;
  try {
    if (token) {
      const requestOptions = {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(citation),
      };
      const queryResponse = await fetch(`${config.appApi}/citations`, requestOptions);
      response = await queryResponse.json();
    }
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const submitOrder = async (token, order) => {
  let response;
  try {
    if (token) {
      const requestOptions = {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(order),
      };
      const queryResponse = await fetch(`${config.appApi}/user/order`, requestOptions);
      response = await queryResponse.json();
    }
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const downloadQuery = async (token, query, filters, showObsolete) => {
  let file;
  try {
    const filterQuery = filters?.length > 0 ? buildFilterQuery(filters) : undefined;
    const queryResponse = await fetch(
      `${config.appApi}/search/download?q=${query}&obsolete=${showObsolete ? 'true' : 'false'}
      ${filterQuery ? `&fq=${filterQuery}` : ''}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    file = await queryResponse.blob();
  } catch (error) {
    console.error(error);
  }
  return file;
};

export const updateLookup = async (token, update) => {
  let job;
  try {
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        type: 'Batch Update',
        config: update,
      }),
    };
    const response = await fetch(`${config.appApi}/system/jobs`, requestOptions);
    job = await response.json();
  } catch (error) {
    console.error(error);
  }
  return job;
};

export const getJobs = async (token) => {
  let jobs;
  try {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetch(`${config.appApi}/system/jobs`, requestOptions);
    jobs = response.json();
  } catch (error) {
    console.error(error);
  }
  return jobs;
};

export const getDepartments = async (token) => {
  let departments = [];
  try {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetch(
      `${config.appApi}/system/departments?updated=true`,
      requestOptions
    );
    departments = response.json();
  } catch (error) {
    console.error(error);
  }
  return departments;
};

export const updateUserSession = async (token, session) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(session),
    };
    await fetch(`${config.appApi}/user/session`, requestOptions);
  } catch (error) {
    console.error(error);
  }
};

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase();
  });
}
