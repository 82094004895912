import React from 'react';
import Form from './Form';

/**
 * Wrapper to allow route to refresh
 */
const Edit = () => {
  return <Form />;
};

export default Edit;
