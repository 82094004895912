import React, { useRef, useState, useEffect } from 'react';
import { getSuggestions } from '../../data';
import { LinkIcon, SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import { useOktaAuth } from '@okta/okta-react';

const SearchBar = ({
  value,
  handleInput,
  handleSubmit,
  clearInput,
  loading,
  search,
  searchHistory,
  currentSearch,
  favorites,
  favoriteHandler,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const { authState } = useOktaAuth();
  let delayDebounceFn;
  let filteredFavorites = value
    ? favorites.filter((f) => f.title?.toLowerCase().startsWith(value.toLowerCase()))
    : [];

  const inputRef = useRef(null);
  const suggestionRef = useRef(null);
  const historyRef = useRef(null);
  const favoriteRef = useRef(null);

  // Get suggestions after 0.25s delay
  useEffect(() => {
    delayDebounceFn = setTimeout(() => {
      if (value !== '')
        getSuggestions(value).then((res) => setSuggestions(res.suggest.suggestions.slice(0, 10)));
      else setSuggestions([]);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  // Handle keydown events for arrowup and arrowdown
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  function onKeyDown(e) {
    /* this code is throwing a lot of null errors, let's protect against that 
       with the following checks. */
    if (suggestionRef?.current === null) {
      return;
    }
    if ((suggestions?.length ?? 0 === 0) && (searchHistory?.length ?? 0 === 0)) {
      return;
    }

    const isUp = e.key === 'ArrowUp';
    const isDown = e.key === 'ArrowDown';
    const isEnter = e.key === 'Enter';
    const inputIsFocused = document.activeElement === inputRef.current;
    let listItems, activeIndex, favoriteItems, suggestionItems;
    if (!isEnter) {
      if (historyRef.current) {
        listItems = Array.from(historyRef.current.children);
        activeIndex = listItems.findIndex((child) => {
          return child.querySelector('#suggestions') === document.activeElement;
        });
      } else {
        if (favoriteRef.current) {
          favoriteItems = Array.from(favoriteRef.current.children);
          suggestionItems = Array.from(suggestionRef.current.children);
          listItems = favoriteItems.concat(suggestionItems);
          activeIndex = listItems.findIndex((child) => {
            return child.querySelector('#suggestions') === document.activeElement;
          });
        } else {
          listItems = Array.from(suggestionRef.current.children);
          activeIndex = listItems.findIndex((child) => {
            return child.querySelector('#suggestions') === document.activeElement;
          });
        }
      }
      // key arrowup
      if (isUp) {
        if (inputIsFocused) {
          listItems[listItems.length - 1].querySelector('#suggestions').focus();
        } else if (listItems[activeIndex - 1]) {
          listItems[activeIndex - 1].querySelector('#suggestions').focus();
        } else {
          inputRef.current.focus();
          clearFocus();
        }
      }
      // key arrowdown
      if (isDown) {
        if (inputIsFocused) {
          listItems[0].querySelector('#suggestions').focus();
        } else if (listItems[activeIndex + 1]) {
          listItems[activeIndex + 1].querySelector('#suggestions').focus();
        } else {
          inputRef.current.focus();
          clearFocus();
        }
      }
    }
  }

  // Clear focus on submit and escape, to hide suggestions.
  const onEscape = function (action) {
    window &&
      window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          action();
        }
      });
  };
  onEscape(() => {
    clearFocus();
  });

  const clearFocus = () => {
    try {
      inputRef.current.blur();
    } catch (error) {
      console.log('Cleared focus.');
    }
  };

  const handleSearchButton = (e) => {
    if (delayDebounceFn) {
      clearTimeout(delayDebounceFn);
    }
    handleSubmit(e);
  };

  const handleQuickSearch = (value) => {
    search(value);
    clearFocus();
  };

  const handleSuggestionSearch = (title) => {
    search(title, true);
    clearFocus();
  };

  return (
    <div className="lg:px-16 w-full lg:w-5/6 max-w-screen-lg mx-auto group">
      <form
        className="flex h-14 bg-white"
        onSubmit={(e) => {
          e.preventDefault();
          clearFocus();
        }}
      >
        <div className="flex border-y-2 border-l-2 rounded-l-lg flex-auto px-1 w-5/6">
          <label htmlFor="search-input" className="px-1 md:px-3 flex items-center">
            <span className="sr-only">
              Search by keyword, title, or Children&apos;s item number...
            </span>
            <SearchIcon className="hidden md:block h-6 text-gray-400 group-focus-within:text-gray-500 transition-colors" />
          </label>
          <input
            type="text"
            id="search-input"
            ref={inputRef}
            placeholder="Search by keyword, title, or Children's item number..."
            className="flex-auto py-6 lg:text-base border-none text-sm leading-6 text-gray-500 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400"
            value={value}
            onChange={handleInput}
            autoComplete="off"
          />
          <XCircleIcon
            className={[
              'h-8 mr-2 self-center text-gray-400 hover:text-gray-500 transition-colors cursor-pointer',
              `${!currentSearch && value.length === 0 ? 'hidden' : undefined}`,
            ].join(' ')}
            onClick={clearInput}
          />
        </div>
        <button
          className="w-1/6 rounded-r-lg text-white bg-primary hover:bg-primary-3"
          onClick={handleSearchButton}
        >
          <span className="hidden md:inline">Search</span>
          <SearchIcon className="md:hidden h-6 text-white mx-auto" />
        </button>
      </form>
      {/* Recent searches */}
      {searchHistory && searchHistory.length > 0 && value.length === 0 && !loading && (
        <div className="flex flex-col relative z-10 invisible group-focus-within:visible">
          <div className="mt-2 md:w-5/6 bg-white flex flex-col border-2 absolute border-gray-200 py-3 rounded-lg">
            <h1 className="px-4 mb-3 text-sm font-medium">Recent searches</h1>
            <div ref={historyRef}>
              {searchHistory.map((search, index) => {
                return (
                  <div key={index}>
                    <div
                      tabIndex="-1"
                      id="suggestions"
                      className="flex flex-auto px-1 py-2 hover:bg-gray-100 items-center hover:cursor-pointer focus:outline-none focus-visible:bg-gray-100"
                      onMouseDown={(e) => e.preventDefault()}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleQuickSearch(search);
                        }
                      }}
                      onClick={() => handleQuickSearch(search)}
                    >
                      <label
                        htmlFor="suggestion"
                        className="flex-none px-3 hover:cursor-pointer flex"
                      >
                        <SearchIcon className="h-6 text-gray-400 group-focus-within:text-gray-500 transition-colors" />
                      </label>
                      <p
                        id="suggestion"
                        className="flex-auto px-3 border-none text-sm text-gray-500"
                      >
                        {search}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {/* Suggestions */}
      {suggestions.length > 0 && (
        <div className="flex flex-col relative z-10 invisible group-focus-within:visible">
          <div className="mt-2 md:w-5/6 bg-white flex flex-col border-2 absolute border-gray-200 py-3 rounded-lg">
            {authState &&
              authState.isAuthenticated &&
              favorites &&
              filteredFavorites.length > 0 && (
                <div className="mb-2">
                  <h1 className="px-4 mb-3 text-sm font-medium">Favorites</h1>
                  <div ref={favoriteRef}>
                    {filteredFavorites.map((favorite, index) => {
                      return (
                        <div key={index}>
                          <div
                            tabIndex="-1"
                            id="suggestions"
                            className="flex flex-auto px-1 py-2 hover:bg-gray-100 items-center hover:cursor-pointer focus:outline-none focus-visible:bg-gray-100"
                            onMouseDown={(e) => e.preventDefault()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                favoriteHandler(favorite);
                              }
                            }}
                            onClick={() => favoriteHandler(favorite)}
                          >
                            <label
                              htmlFor="favorite"
                              className="flex-none px-3 hover:cursor-pointer flex"
                            >
                              <LinkIcon className="h-6 text-gray-400 group-focus-within:text-gray-500 transition-colors" />
                            </label>
                            <p
                              id="favorite"
                              className="flex-auto px-3 border-none text-sm text-gray-500"
                            >
                              {favorite.title}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            {suggestions.filter(
              (s) => !filteredFavorites.map((f) => f.title).includes(s.suggestion)
            ).length > 0 && (
              <>
                <h1 className="px-4 mb-3 text-sm font-medium">Suggestions</h1>
                <div ref={suggestionRef}>
                  {suggestions
                    .filter((s) => !filteredFavorites.map((f) => f.title).includes(s.suggestion))
                    .slice(0, 10 - filteredFavorites.length)
                    .map((suggestion, index) => {
                      return (
                        <div key={index}>
                          <div
                            tabIndex="-1"
                            id="suggestions"
                            className="flex flex-auto px-1 py-2 hover:bg-gray-100 items-center hover:cursor-pointer focus:outline-none focus-visible:bg-gray-100"
                            onMouseDown={(e) => e.preventDefault()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSuggestionSearch(suggestion.suggestion);
                              }
                            }}
                            onClick={() => handleSuggestionSearch(suggestion.suggestion)}
                          >
                            <label
                              htmlFor="suggestion"
                              className="flex-none px-3 hover:cursor-pointer flex"
                            >
                              <SearchIcon className="h-6 text-gray-400 group-focus-within:text-gray-500 transition-colors" />
                            </label>
                            <p
                              id="suggestion"
                              className="flex-auto px-3 border-none text-sm text-gray-500"
                            >
                              {suggestion.suggestion}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
