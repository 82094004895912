import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useCitation, useTitle } from '../../hooks';
import { useOktaAuth } from '@okta/okta-react';
import { addCitation, updateCitation } from '../../data';
import { removeEmptyOrNull } from '../../util';
import { globalContext } from '../../contexts';
import Input from '../common/Input';
import ButtonFill from '../common/ButtonFill';
import Modal from '../common/Modal';
import LoadingOverlay from '../common/LoadingOverlay';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { DevTool } from '@hookform/devtools';
import Languages from './Languages';
import ErrorSummary from './ErrorSummary';

const Form = () => {
  const { id: citationId } = useParams();
  const [citation, loading] = useCitation(citationId);
  const { departments, facets, isLoading, updateFacets, user } = globalContext();
  const methods = useForm();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;
  const [showSuccess, setShowSuccess] = useState(false);
  const [returnId, setReturnId] = useState(citationId);
  const navigate = useNavigate();
  const addNew = !citationId;

  const { authState } = useOktaAuth();
  const accessToken = authState?.isAuthenticated ? authState.accessToken.accessToken : '';

  // Get latest revisions
  let latestRevisions = {};
  const revisionDates = citation?.record?.revisionDates;
  if (revisionDates) {
    for (const revision of revisionDates) {
      if (!Object.hasOwn(latestRevisions, revision.lang)) {
        latestRevisions[revision.lang] = revision.revisionDate;
      } else if (new Date(revision.revisionDate) > new Date(latestRevisions[revision.lang])) {
        latestRevisions[revision.lang] = revision.revisionDate;
      }
    }
  }

  // Prepopulate form.
  useEffect(() => {
    if (citation) {
      reset(citation.record);
    }
    console.log(citation);
  }, [citation]);

  if (loading || !facets || !Object.hasOwn(user, 'admin')) {
    useTitle('Loading...');
    return <LoadingOverlay />;
  }

  // Redirect to home if user is not an admin.
  if (!user.admin) {
    history.push('/');
  }

  if (!addNew && citation.length == 0) {
    useTitle('No citation found');
    return (
      <div className="mx-auto mt-16 text-center text-2xl text-gray-500">
        <p>No citation found.</p>
      </div>
    );
  }

  // Format for react-select
  const getFacetValues = (facetName) =>
    facets[facetName]?.map((item) => ({ label: item.value, value: item.value }));
  const formatValues = (values) => values?.map((item) => ({ label: item, value: item }));

  const booleanOptions = formatValues(['Yes', 'No', 'N/A']);
  const booleanValueMap = { Yes: 1, No: 0, 'N/A': -1 };
  const getBooleanLabel = (value) => {
    if (value == 1) return { label: 'Yes', value: 'Yes' };
    if (value == 0) return { label: 'No', value: 'No' };
    if (value == -1) return { label: 'N/A', value: 'N/A' };
    return '';
  };

  const dateKeys = ['deptReviewDate', 'publishDate'];

  const onSubmit = async (data) => {
    console.log('Submitting data...');
    // Format dates
    dateKeys.forEach((elem) => {
      if (data[elem]) data[elem] = new Date(data[elem]).toISOString();
    });

    // If new revision date, add to array.
    if (data['languages']) {
      data['languages'].forEach((elem) => {
        if (elem.revisionDate) {
          // Init array if undefined
          if (!data['revisionDates']) data['revisionDates'] = [];

          // Update revision date if new revision date is prior to the latest date
          if (new Date(elem.revisionDate) < new Date(latestRevisions[elem.lang])) {
            data['revisionDates'].map((revision) => {
              if (
                revision.lang == elem.lang &&
                revision.revisionDate == latestRevisions[elem.lang]
              ) {
                revision.revisionDate = new Date(elem.revisionDate).toISOString();
              }
            });
          } else {
            data['revisionDates'].push({
              revisionDate: new Date(elem.revisionDate).toISOString(),
              lang: elem.lang,
            });
          }
          delete elem.revisionDate;
        }
      });
    }

    // Strip empty values/null
    const submission = { ...data };
    removeEmptyOrNull(submission);
    console.log(submission);

    // Put or post data
    const response = await (addNew
      ? addCitation(accessToken, submission)
      : updateCitation(citationId, accessToken, submission));
    if (response) {
      // Add returns an array of results
      // Update returns a single object
      const result = Array.isArray(response) ? response[0] : response;
      setReturnId(result?.readableIds[0]?.id || result?.id);
      updateFacets(30);
      setShowSuccess(true);
    }
  };

  const returnToView = () => {
    setShowSuccess(false);
    navigate(`/citation/${returnId}`);
  };

  useTitle(addNew ? 'Adding New Resource' : `Editing — ${citation.record?.title}`);

  return (
    // Sticky header
    <>
      <div
        className={`sticky shadow top-0 px-7 py-4 -mt-5 -mx-5 md:py-5 border-b-2 border-gray-100 bg-white z-10 flex justify-end`}
      >
        <div className="flex gap-2">
          <Link to={addNew ? `/` : `/citation/${citation.readableIds[0]?.id || citation.id}`}>
            <button className="px-4 py-2 text-sm rounded-lg border-2 border-gray-300 text-gray-900 hover:text-white hover:border-secondary hover:bg-secondary">
              Cancel
            </button>
          </Link>
          <ButtonFill text="Save Changes" onButtonClick={handleSubmit(onSubmit)} />
        </div>
      </div>
      <div className="mx-24 my-16 max-w-screen">
        <FormProvider {...methods}>
          <form className="flex flex-col gap-8" onSubmit={methods.handleSubmit(onSubmit)}>
            <p className="text-red-500 my-3">All fields marked with an * are required</p>
            <h1 className="text-xl font-medium mb-4">{addNew ? 'Add' : 'Edit'} - General</h1>
            <div className="flex items-center">
              <label htmlFor="title" className="w-44">
                Title: <span className="text-red-500">*</span>
              </label>
              <Input
                type="text"
                className={errors.title ? 'border-secondary' : 'border-gray-300'}
                {...register('title', { required: true })}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="authors" className="w-44">
                Authors: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="authors"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('authors')}
                    isMulti
                    value={formatValues(value)}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val.map((option) => option.value))}
                    styles={customStyles}
                    error={errors.authors}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="publisher" className="w-44">
                Publishers: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="publisher"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('publisher_name')}
                    value={
                      value && {
                        label: value.name,
                        value: value.name,
                      }
                    }
                    onBlur={onBlur}
                    onChange={(val) => onChange({ name: val.value })}
                    styles={customStyles}
                    error={errors.publisher}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="materialFormat" className="w-44">
                Material Format: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="materialFormat"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('material_format')}
                    value={value && { label: value, value: value }}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val.value)}
                    styles={customStyles}
                    error={errors.materialFormat}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="keywords" className="w-44">
                Keywords: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="keywords"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('keywords')}
                    isMulti
                    value={formatValues(value)}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val.map((option) => option.value))}
                    styles={customStyles}
                    error={errors.keywords}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="keySubjectHeading" className="w-44">
                Subject Heading: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="keySubjectHeading"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('subject_heading')}
                    value={value && { label: value, value: value }}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val.value)}
                    styles={customStyles}
                    error={errors.keySubjectHeading}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="whereToObtain" className="w-44">
                Where to Obtain: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="whereToObtain"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('where_to_obtain')}
                    value={value && { label: value, value: value }}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val.value)}
                    styles={customStyles}
                    error={errors.whereToObtain}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="tags" className="w-44">
                Tags:
              </label>
              <Controller
                control={control}
                name="tags"
                rules={{ required: false }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('tags')}
                    isMulti
                    value={formatValues(value)}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val.map((option) => option.value))}
                    styles={customStyles}
                    error={errors.tags}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="publishDate" className="w-44">
                Publication Date: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="publishDate"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    className={`${
                      errors.deptReviewDate ? 'border-secondary' : 'border-gray-300'
                    } rounded w-112 shrink-0`}
                    name={name}
                    type="date"
                    value={value?.split('T')[0] || ''}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-start">
              <label htmlFor="printingSpecs" className="w-44">
                Printing Specs:
              </label>
              <textarea
                className={`${
                  errors.printingSpecs ? 'border-secondary' : 'border-gray-300'
                } rounded w-[36rem] h-40 max-h-96`}
                {...register('printingSpecs', { required: false })}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="obsolete" className="w-44">
                Obsolete:
              </label>
              <input
                type="checkbox"
                id="obsolete"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                {...register('obsolete')}
              />
            </div>
            <hr />
            {/* Revision Dates */}
            <h1 className="text-xl font-medium mb-4">
              {addNew ? 'Add' : 'Edit'} - Language, Revision Dates, and Links
            </h1>
            <Controller
              control={control}
              name="languages"
              rules={{ required: true }}
              render={({ field }) => (
                <Languages
                  {...field}
                  borderColor={
                    errors.languages && errors.languages.length == 0 && 'border border-secondary'
                  }
                  revisionDates={revisionDates || []}
                  values={getFacetValues('languages')}
                />
              )}
            />
            <hr />
            <h1 className="text-xl font-medium mb-4">
              {addNew ? 'Add' : 'Edit'} - Format and Audience
            </h1>
            <div className="flex items-start">
              <label htmlFor="abstract" className="w-44">
                Abstract: <span className="text-red-500">*</span>
              </label>
              <textarea
                className={`${
                  errors.abstract ? 'border-secondary' : 'border-gray-300'
                } rounded w-[36rem] h-40 max-h-96`}
                {...register('abstract', { required: true })}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="audience" className="w-44">
                Target Audience: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="audience"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={getFacetValues('audience')}
                    isMulti
                    value={formatValues(value)}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val.map((option) => option.value))}
                    styles={customStyles}
                    error={errors.audience}
                    isLoading={isLoading}
                  />
                )}
              />
            </div>
            <hr />
            <h1 className="text-xl font-medium mb-4">
              {addNew ? 'Add' : 'Edit'} - Health Ed Review
            </h1>
            <div className="flex items-center">
              <label htmlFor="reviewedBy" className="w-44">
                HE/CS Reviewer: <span className="text-red-500">*</span>
              </label>
              <Input
                type="text"
                className={errors.reviewedBy ? 'border-secondary' : 'border-gray-300'}
                {...register('reviewedBy', { required: true })}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="deptSubmittedBy" className="w-44">
                Dept. Submitted By:
              </label>
              <Controller
                control={control}
                name="deptSubmittedBy"
                rules={{ required: false }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Creatable
                    className="rounded w-112 shrink-0"
                    name={name}
                    options={
                      departments?.map((dept) => ({ label: dept.name, value: dept.name })) || []
                    }
                    value={value && { label: value.name, value: value.name }}
                    onBlur={onBlur}
                    onChange={(val) => onChange(val && { name: val.value })}
                    styles={customStyles}
                    error={errors.deptSubmittedBy}
                    isLoading={isLoading}
                    isClearable
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="assessment" className="w-44">
                PMAT/SAM: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="assessment"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Select
                    className="rounded w-32 shrink-0"
                    name={name}
                    options={booleanOptions}
                    value={getBooleanLabel(value)}
                    onBlur={onBlur}
                    onChange={(val) => onChange(booleanValueMap[val.value])}
                    styles={customStyles}
                    error={errors.assessment}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="famReview" className="w-44">
                Family Review: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="famReview"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Select
                    className="rounded w-32 shrink-0"
                    name={name}
                    options={booleanOptions}
                    value={getBooleanLabel(value)}
                    onBlur={onBlur}
                    onChange={(val) => onChange(booleanValueMap[val.value])}
                    styles={customStyles}
                    error={errors.famReview}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="originator" className="w-44">
                Production Specialist:
              </label>
              <Input
                type="text"
                className={errors.productionSpecialist ? 'border-secondary' : 'border-gray-300'}
                {...register('productionSpecialist', { required: false })}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="originator" className="w-44">
                Content Reviewer: <span className="text-red-500">*</span>
              </label>
              <Input
                type="text"
                className={errors.originator ? 'border-secondary' : 'border-gray-300'}
                {...register('originator', { required: true })}
              />
            </div>
            <div className="flex items-center">
              <label htmlFor="deptReviewDate" className="w-44">
                Dept. Review Date: <span className="text-red-500">*</span>
              </label>
              <Controller
                control={control}
                name="deptReviewDate"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    className={`${
                      errors.deptReviewDate ? 'border-secondary' : 'border-gray-300'
                    } rounded w-112 shrink-0`}
                    name={name}
                    type="date"
                    value={value?.split('T')[0] || ''}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <hr />
            <h1 className="text-xl font-medium mb-4">{addNew ? 'Add' : 'Edit'} - Notes</h1>
            <div className="flex items-start">
              <label htmlFor="notes" className="w-44">
                Notes:
              </label>
              <textarea
                className="rounded border border-gray-300 w-112 shrink-0 h-32 max-h-96"
                {...register('notes')}
              />
            </div>
            {/* Temporary error summary */}
            <DevTool control={control} />
            <ErrorSummary errors={errors} />
          </form>
        </FormProvider>
      </div>
      {/* Success modal */}
      <Modal
        headline="Success!"
        text={`Your citation has been ${addNew ? 'added' : 'updated'}.`}
        cta="Ok. Return to view."
        isOpen={showSuccess}
        accept={returnToView}
      />
    </>
  );
};

/**
 * Define custom styles for select components.
 */
const customStyles = {
  input: (provided) => ({
    ...provided,
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  // border-secondary if error, border-gray-300 otherwise
  control: (provided, state) => ({
    ...provided,
    border: state.selectProps.error ? '1px solid #db5a1d' : '1px solid #d1d5db',
  }),
};

export default Form;
