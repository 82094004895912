import React, { useEffect, useState } from 'react';
import { LinkIcon, StarIcon } from '@heroicons/react/solid';
import { globalContext } from '../../contexts';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

export default function FavoritesTable() {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { favorites } = globalContext();
  const itemsPerPage = 5;

  useEffect(() => {
    setPageCount(Math.ceil(favorites.length / itemsPerPage));
    setItemOffset((currentPage - 1) * itemsPerPage);
  }, [favorites]);

  const count = favorites.length;
  const endIndex = itemOffset + 5 > count ? count : itemOffset + itemsPerPage;

  if (favorites.length === 0) return <></>;
  return (
    <div className="lg:px-16 w-full lg:w-5/6 max-w-screen-lg mx-auto mt-12">
      <div className="flex flex-grow items-center mb-2">
        <StarIcon className={`p-1 w-8 h-8 ml-1 fill-primary stroke-primary`} />
        <header className="text-xl text-gray-600">Favorites</header>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block sm:px-6 lg:px-8 w-full">
            <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
              <table className="min-w-full">
                <tbody className="bg-white divide-y divide-gray-100">
                  {favorites
                    .sort((a, b) => a.title > b.title)
                    .slice(itemOffset, itemOffset + itemsPerPage)
                    .map((favorite, index) => (
                      <tr key={index}>
                        <td className="px-3 py-3">
                          <Link
                            to={`/citation/${favorite.readableId ?? favorite.id}`}
                            rel="noopener noreferrer"
                          >
                            <span className="flex text-md text-primary hover:text-primary-3 inline items-center">
                              <LinkIcon className="h-5 text-gray-400 mr-2 shrink-0" />
                              {favorite.title}
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3">
        <nav className="flex flex-col md:flex-row md:justify-between">
          <p className="text-gray-500">
            Showing <span className="font-bold">{itemOffset + 1}</span> to{' '}
            <span className="font-bold">{endIndex}</span> of{' '}
            <span className="font-bold">{count}</span> results
          </p>
          <ReactPaginate
            className="inline-flex my-4 md:my-0"
            pageClassName="px-2 text-primary"
            activeClassName="bg-primary text-white rounded"
            breakLabel="..."
            breakClassName="px-2 text-primary"
            marginPagesDisplayed={2}
            nextClassName="pl-4 text-primary"
            nextLabel="Next >"
            onPageChange={(e) => {
              setCurrentPage(e.selected + 1);
              setItemOffset((e.selected * 5) % favorites.length);
            }}
            pageRangeDisplayed={screen.width >= 768 ? itemsPerPage : 2}
            pageCount={pageCount}
            forcePage={pageCount ? itemOffset / itemsPerPage : -1}
            previousClassName="pr-4 text-primary"
            previousLabel="< Previous"
            disabledLinkClassName="hidden"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
    </div>
  );
}
