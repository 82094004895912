const CollectionType = {
  Favorites: 0,
  Owned: 1,
  Shared: 2,
};

const FacetLabels = {
  audience: 'Audience',
  authors: 'Authors',
  departments: 'Departments',
  folder: 'Folders',
  keywords: 'Keywords',
  languages: 'Languages',
  material_format: 'Format',
  paper_colors: 'Paper Colors',
  publisher_name: 'Publisher',
  subject_heading: 'Subject Heading',
  tags: 'Tags',
  where_to_obtain: 'Where to Obtain',
};

export { CollectionType, FacetLabels };
