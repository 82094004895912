import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

// Modal in either primary or secondary color
export default function Modal({ headline, text, cta, isOpen, accept, cancel, secondary }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto backdrop-blur-xl"
          onClose={cancel ? cancel : accept}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {headline}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{text}</p>
                </div>

                <div className="mt-4 flex gap-2">
                  {cancel ? (
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 border rounded-md border-primary hover:bg-primary hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary"
                      onClick={cancel}
                    >
                      Cancel
                    </button>
                  ) : null}
                  <button
                    type="button"
                    className={`inline-flex justify-center px-4 py-2 text-sm font-medium border rounded-md focus:outline-none hover:text-white focus-visible:ring-2 focus-visible:ring-offset-2 ${
                      secondary
                        ? 'text-secondary border-secondary hover:bg-secondary focus-visible:ring-secondary'
                        : 'text-primary border-primary hover:bg-primary focus-visible:ring-primary'
                    }`}
                    onClick={accept}
                  >
                    {cta}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
