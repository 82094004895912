import React from 'react';

const getErrorArray = (errors) => {
  const errorArray = [];

  Object.entries(errors).map(([k, v]) =>
    Array.isArray(v)
      ? v.length === 0
        ? errorArray.push(`${k} is required.`)
        : v.forEach((err) => err && getErrorArray(err).map((error) => errorArray.push(error)))
      : errorArray.push(`${k === 'lang' ? 'language' : k} is required.`)
  );

  return errorArray;
};

const ErrorSummary = ({ errors }) => {
  console.log(errors);
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return (
    <div className="error-summary">
      {getErrorArray(errors).map((error, idx) => (
        <div key={`${idx}`} className="text-secondary">
          {error}
        </div>
      ))}
    </div>
  );
};

export default ErrorSummary;
