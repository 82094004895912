import { PencilIcon, StarIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { useTitle, useCitation } from '../hooks';
import { formatDate, languageSort } from '../util';
import Table from './common/Table';
import QRCModal from './common/QRCModal';
import { globalContext } from '../contexts';
import { useOktaAuth } from '@okta/okta-react';

const Details = () => {
  const { id: citationId } = useParams();
  const [citation, loading, redirect] = useCitation(citationId);
  const { favorites, order, setFavorites, setOrder, user } = globalContext();
  const location = useLocation();
  const [qrUrl, setQRUrl] = useState();
  const [resourceUrl, setResourceUrl] = useState();
  const [displayQRCode, setDisplayQRCode] = useState(false);

  const { authState } = useOktaAuth();

  if (loading) {
    if (redirect && location.pathname != redirect) {
      useTitle('Redirecting...');
      return (
        <>
          <SkeletonDetails />
          <Navigate to={redirect} replace />
        </>
      );
    } else {
      useTitle('Loading Citation...');
      return <SkeletonDetails />;
    }
  }

  if (!citation?.id) {
    useTitle('No citation found');
    return (
      <div className="mx-auto mt-16 text-center text-2xl text-gray-500">
        <p>No citation found.</p>
      </div>
    );
  }

  const fields = citation.record;
  const isFavorite = () => favorites.findIndex((f) => f.id === citation.id) >= 0;
  useTitle(fields.title);

  const processFields = (fields) => {
    const rows = [];
    const displayStrings = {
      authors: 'Author:',
      publisher: 'Publisher:',
      keywords: 'Keywords:',
      audience: 'Audience:',
      materialFormat: 'Format:',
      publishDate: 'Publication Date:',
      deptReviewDate: 'Dept. Review Date:',
      whereToObtain: 'Where to Obtain:',
      keySubjectHeading: 'Key Subject:',
      deptSubmittedBy: 'Dept. Submitted By:',
    };
    for (const [jsonKey, formattedKey] of Object.entries(displayStrings)) {
      const displayKey = formattedKey;
      const field = fields[jsonKey];

      // if (!field) continue;
      if (jsonKey == 'publishDate' || jsonKey == 'deptReviewDate') {
        rows.push(
          <tr key={displayKey}>
            <td className="pr-6 py-2 text-gray-500">{displayKey}</td>
            <td>{field ? formatDate(field) : ''}</td>
          </tr>
        );
      } else if (jsonKey == 'publisher' || jsonKey == 'deptSubmittedBy') {
        rows.push(
          <tr key={displayKey}>
            <td className="pr-6 py-2 text-gray-500">{displayKey}</td>
            <td>{field ? field.name : ''}</td>
          </tr>
        );
      } else if (Array.isArray(field)) {
        rows.push(
          <tr key={displayKey}>
            <td className="pr-6 py-2 text-gray-500">{displayKey}</td>
            <td>{field ? field.join(', ') : ''}</td>
          </tr>
        );
      } else {
        rows.push(
          <tr key={displayKey}>
            <td className="pr-6 py-2 text-gray-500">{displayKey}</td>
            <td>{field}</td>
          </tr>
        );
      }
    }
    return rows;
  };

  const handleOrder = (lang) => {
    const itemIdx = order.findIndex(
      (item) => item.citationId === lang.citationId && item.lang == lang.lang
    );
    if (itemIdx >= 0) {
      order[itemIdx].quantity += 1;
      setOrder(order);
    } else {
      setOrder([
        ...order,
        {
          id: citation.id,
          itemNum: lang.ksbgNumber,
          lang: lang.lang,
          quantity: 1,
          readableId: citation.readableIds[0]?.id,
          title: citation.record.title,
          url: lang.url,
          printingSpecs: citation.record.printingSpecs,
        },
      ]);
    }
  };

  const onQRCode = (url, resourceUrl) => {
    setQRUrl(url);
    setResourceUrl(resourceUrl);
    setDisplayQRCode(true);
  };

  const updateFavorite = (id, title) => {
    setFavorites(
      isFavorite()
        ? favorites.filter((f) => f.id !== id)
        : [
            ...favorites,
            {
              id,
              title,
            },
          ]
    );
  };

  return (
    <div className="mx-2 md:mx-24 my-4 md:my-16 max-w-screen-lg">
      <article className="pb-8 leading-relaxed border-b-2">
        <div className="flex justify-between items-start">
          <div className="flex flex-col flex-grow">
            <div className="flex flex-grow  mb-2">
              <header className="font-medium text-2xl text-gray-900 items-center">
                <span className="items-center">
                  {fields.title}
                  {authState && authState.isAuthenticated && (
                    <StarIcon
                      className={`inline-block w-7 h-7 pb-1 ml-1 ${
                        isFavorite() ? 'fill-primary' : 'fill-white'
                      } stroke-primary cursor-pointer`}
                      onClick={() => updateFavorite(citation.id, fields.title)}
                    />
                  )}
                </span>
              </header>
            </div>
            {fields.obsolete && (
              <span className="ml-4 text-secondary text-xs uppercase">Obsolete</span>
            )}
            {fields.languages && (
              <ul className="flex flex-wrap justify-start items-start">
                {fields.languages.sort(languageSort).map((langObj, index) => (
                  <li
                    key={index}
                    className="text-sm text-gray-400 after:content-['•'] after:mr-1 after:ml-1 last:after:content-['']"
                  >
                    {langObj.lang}
                  </li>
                ))}
              </ul>
            )}
            {fields.printingSpecs && <p className="prose prose-slate">{fields.printingSpecs}</p>}
          </div>
          {user.admin && (
            <Link
              to={`/citation/edit/${citationId}`}
              className="hidden md:block text-lg whitespace-nowrap"
            >
              <button className="px-4 py-2 text-sm rounded-lg border-2 inline-flex items-center gap-2 border-gray-300 text-gray-900 hover:text-primary hover:border-primary">
                <PencilIcon className="w-4 h-4 text-primary" />
                <span>Edit</span>
              </button>
            </Link>
          )}
        </div>
        <h1 className="font-bold mt-8 mb-2 text-gray-900">Abstract</h1>
        <p className="prose prose-slate">{fields.abstract}</p>
        {fields.languages && (
          <div className="mt-10 mb-4">
            <Table
              citationId={citation.id}
              languages={fields.languages.sort(languageSort)}
              revisionDates={fields.revisionDates}
              inOrder={order?.reduce(
                (prev, curr) => prev.push({ citationId: curr.id, lang: curr.lang }) && prev,
                []
              )}
              onOrder={handleOrder}
              whereToObtain={fields.whereToObtain}
              onQRCode={onQRCode}
              isObsolete={citation.record.obsolete}
              materialFormat={citation.record.materialFormat}
              publishedBySC={
                citation.record.publisher?.name?.toLowerCase() === "seattle children's"
              }
            />
          </div>
        )}
      </article>
      <section className="pt-10 leading-relaxed">
        <h1 className="font-bold mb-4">General Information</h1>
        <table>
          <tbody>{processFields(fields)}</tbody>
        </table>
      </section>
      <QRCModal
        isOpen={displayQRCode}
        url={qrUrl}
        resourceUrl={resourceUrl}
        title={citation.record.title}
        onClose={() => setDisplayQRCode(false)}
      />
    </div>
  );
};

const SkeletonDetails = () => {
  return (
    <div className="mx-2 md:mx-24 my-4 md:my-16 max-w-screen-lg animate-pulse">
      <article className="pb-8 leading-relaxed border-b-2">
        <div className="rounded mb-4 h-7 w-3/5 bg-gray-300" />
        <div className="flex gap-2 flex-wrap">
          <div className="rounded-lg bg-gray-300 h-4 w-14" />
          <div className="rounded-lg bg-gray-300 h-4 w-14" />
        </div>
        <div className="rounded mt-8 mb-3 bg-gray-300 h-5 w-20" />
        <div className="prose">
          <div className="rounded bg-gray-300 h-4 w-full" />
          <div className="rounded bg-gray-300 h-4 w-full my-3" />
          <div className="rounded bg-gray-300 h-4 w-2/3" />
        </div>
        <div className="rounded-lg mt-10 mb-4 bg-gray-300 h-32 w-3/4" />
      </article>
      <section className="pt-10 leading-relaxed">
        <div className="rounded mb-4 bg-gray-300 h-5 w-40" />
        {[...Array(10)].map((_, i) => (
          <div className="rounded my-4 bg-gray-300 h-4 w-1/2" key={i} />
        ))}
      </section>
    </div>
  );
};

export default Details;
