import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { formatDate, getQRRedirectUrl } from '../../util';

export default function Table({
  citationId,
  languages,
  revisionDates,
  onOrder,
  inOrder,
  whereToObtain,
  onQRCode,
  isObsolete,
  materialFormat,
  publishedBySC,
}) {
  const { authState, oktaAuth } = useOktaAuth();

  // Get latest revisions
  let latestRevisions = {};
  if (revisionDates) {
    for (const revision of revisionDates) {
      if (!Object.hasOwn(latestRevisions, revision.lang)) {
        latestRevisions[revision.lang] = revision.revisionDate;
      } else if (new Date(revision.revisionDate) > new Date(latestRevisions[revision.lang])) {
        latestRevisions[revision.lang] = revision.revisionDate;
      }
    }
  }

  const addToOrder = async (e, lang) => {
    e.preventDefault();
    if (authState.isAuthenticated) {
      onOrder && onOrder(lang);
    } else {
      oktaAuth.signInWithRedirect({ originalUri: window.location });
    }
  };

  const displayQRCode = (lang) => lang.url && publishedBySC;

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Language
                  </th>
                  <th
                    scope="col"
                    className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Children&apos;s Item Number
                  </th>
                  <th
                    scope="col"
                    className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Revision Date
                  </th>
                  {publishedBySC && (
                    <th
                      scope="col"
                      className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      QR Code
                    </th>
                  )}
                  <th scope="col" className="relative px-2 md:px-6 py-1 md:py-3">
                    <span className="sr-only">
                      {materialFormat === 'Video' ? 'View' : 'View and Print'}
                    </span>
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Order</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {languages.map((lang, index) => (
                  <tr key={index}>
                    <td className="px-2 md:px-6 py-2 md:py-6 whitespace-nowrap">
                      <div className="text-gray-900">{lang.lang}</div>
                    </td>
                    <td className="px-2 md:px-6 py-2 md:py-6 whitespace-nowrap">
                      <div className="text-gray-900 tracking-wider">{lang.ksbgNumber}</div>
                    </td>
                    <td className="px-2 md:px-6 py-2 md:py-6 whitespace-nowrap">
                      <div className="text-gray-900">{formatDate(latestRevisions[lang.lang])}</div>
                    </td>
                    {displayQRCode(lang) && (
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8 cursor-pointer mx-auto text-primary hover:text-primary-3"
                          onClick={() => onQRCode(getQRRedirectUrl(citationId, lang.url), lang.url)}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                          />
                        </svg>
                      </td>
                    )}
                    <td className="px-2 md:px-6 py-2 md:py-6 whitespace-nowrap text-sm font-medium">
                      {lang.url && (
                        <a
                          href={lang.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary hover:text-primary-3"
                        >
                          <span className="hidden md:inline-block">
                            {materialFormat === 'Video' ? 'View' : 'View and Print'}
                          </span>
                          <span className="md:hidden">View</span>
                        </a>
                      )}
                    </td>
                    <td className="px-2 md:px-6 py-2 md:py-6 whitespace-nowrap text-sm font-medium">
                      {whereToObtain === 'Copy Center' && !isObsolete ? (
                        inOrder?.filter(
                          (curr) => curr.citationId === citationId && curr.lang === lang.lang
                        ).length > 0 ? (
                          <span className="text-green-700">In Order</span>
                        ) : (
                          <a
                            href="#"
                            onClick={(e) => addToOrder(e, { citationId, ...lang })}
                            className="text-primary hover:text-primary-3"
                          >
                            <span className="hidden md:inline-block">Add to Order</span>
                          </a>
                        )
                      ) : undefined}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
