import React from 'react';
import { Link } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import { StarIcon } from '@heroicons/react/solid';
import { languageStrSort } from '../../util';
import { globalContext } from '../../contexts';
import { useOktaAuth } from '@okta/okta-react';

const ResultsList = (props) => {
  const { authState } = useOktaAuth();
  const { results, loading } = props;
  const { favorites, setFavorites } = globalContext();

  const updateFavorite = (id, title) => {
    setFavorites(
      favorites.findIndex((f) => f.id === id) >= 0
        ? favorites.filter((f) => f.id !== id)
        : [
            ...favorites,
            {
              id,
              title,
            },
          ]
    );
  };
  return (
    <div>
      <ul className="divide-y">
        {loading
          ? [...Array(10)].map((_, i) => <SkeletonResult key={i} />)
          : results.hits.hit.map((hit) => (
              <Result
                authenticated={authState && authState.isAuthenticated}
                key={hit.id}
                data={hit}
                favorites={favorites}
                onFavorite={updateFavorite}
              />
            ))}
      </ul>
    </div>
  );
};

const Result = ({ authenticated, data, favorites, onFavorite }) => {
  const { abstract } = data.highlights;
  const id = data.id.split(':')[1];
  const { title, languages, obsolete, ksbg_numbers } = data.fields;
  const engItem = ksbg_numbers?.find((item) => /^[A-Z]+\d+$/.test(item));

  return (
    <li className="py-7">
      <div className="float-right">
        {authenticated && (
          <StarIcon
            className={`inline-block w-6 h-6 ml-1 mr-4 ${
              favorites.findIndex((f) => f.id === id) >= 0 ? 'fill-primary' : 'fill-white'
            } stroke-primary cursor-pointer`}
            onClick={() => onFavorite(id, title[0])}
          />
        )}
      </div>
      <div className="flex flex-grow items-center">
        <div>
          <Link to={`/citation/${data.fields.readable_id}`} rel="noopener noreferrer">
            <h1 className="font-medium text-lg text-primary hover:text-primary-3 inline">
              {title}
            </h1>
            {engItem && (
              <span className="p-1 text-primary">
                | <span className="text-sm italic">{engItem}</span>
              </span>
            )}
          </Link>
          {obsolete == 'true' && (
            <span className="text-secondary text-xs uppercase ml-4">Obsolete</span>
          )}
        </div>
      </div>
      {languages && (
        <ul className="flex flex-wrap justify-start items-start">
          {languages.sort(languageStrSort).map((lang, index) => (
            <li
              key={index}
              className="text-sm text-gray-400 after:content-['•'] after:mr-1 after:ml-1 last:after:content-['']"
            >
              {lang}
            </li>
          ))}
        </ul>
      )}
      <p
        className="mt-4"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            abstract
              .replace(/\*[^\\*]+\*/gm, `<span class='bg-yellow-100'>$&</span>`)
              .replace(/\*/gm, '')
          ),
        }}
      ></p>
    </li>
  );
};

const SkeletonResult = () => {
  return (
    <li className="py-7 w-full mx-auto animate-pulse">
      <div className="rounded mb-4 h-6 w-2/5 bg-gray-300" />

      <div className="flex gap-2 flex-wrap">
        <div className="rounded-lg bg-gray-300 h-4 w-14" />
        <div className="rounded-lg bg-gray-300 h-4 w-14" />
      </div>

      <div className="mt-5">
        <div className="rounded bg-gray-300 h-4 w-full" />
        <div className="rounded bg-gray-300 h-4 w-full my-3" />
        <div className="rounded bg-gray-300 h-4 w-2/3" />
      </div>
    </li>
  );
};

export default ResultsList;
