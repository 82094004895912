import React from 'react';

/**
 * Outlined button
 * @param {string} text - Button text
 * @param {function} onButtonClick - onClick
 * @param {boolean} secondary - Use secondary (warning) colors
 */
export default function ButtonOutline({ text, onButtonClick, secondary }) {
  const buttonColor = secondary
    ? 'border-secondary text-secondary hover:text-white hover:border-secondary hover:bg-secondary'
    : 'border-primary text-primary hover:text-white hover:border-primary hover:bg-primary';
  const buttonClass = `px-4 py-2 text-sm rounded-lg border-2 outline-none ${buttonColor}`;
  return (
    <button className={buttonClass} onClick={onButtonClick}>
      {text}
    </button>
  );
}
