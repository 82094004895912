import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { copyToClipboard } from '../../util';
import { ClipboardCheckIcon, ClipboardCopyIcon } from '@heroicons/react/outline';

const Profile = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  if (!userInfo) {
    return (
      <div className="mx-auto mt-16 text-center text-2xl text-gray-500">
        <p>Fetching user profile...</p>
      </div>
    );
  }

  const copyToken = (text) => {
    try {
      copyToClipboard(text);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mx-2 md:mx-24 my-4 md:my-16 max-w-screen-lg">
      <div className="text-2xl font-medium">My User Profile (ID Token Claims)</div>
      <p className="pt-4">
        Below is the information from your ID token which was obtained during the&nbsp;
        <a
          className="text-primary hover:text-primary-3"
          href="https://developer.okta.com/docs/guides/implement-auth-code-pkce"
        >
          PKCE Flow
        </a>{' '}
        and is now stored in local storage.
      </p>
      <p className="pt-4">
        This route is protected with the <code>&lt;SecureRoute&gt;</code> component, which will
        ensure that this page cannot be accessed until you have authenticated.
      </p>
      <p className="mt-6 font-medium">Your Okta Access Token:</p>
      <div>
        <div className="my-4 h-40 max-w-[36rem] break-words overflow-auto border rounded p-2">
          <code className="text-xs">{oktaAuth.getAccessToken()}</code>
        </div>
        <button
          className={`${
            copySuccess
              ? 'text-green-600 border-green-600'
              : 'text-gray-900 border-gray-300 hover:text-primary hover:border-primary'
          } px-4 py-2 text-xs rounded-lg inline-flex items-center gap-2 border-2`}
          onClick={() => copyToken(oktaAuth.getAccessToken())}
        >
          {copySuccess ? (
            <ClipboardCheckIcon className="text-green-600 w-4 h-4" />
          ) : (
            <ClipboardCopyIcon className="text-primary w-4 h-4" />
          )}
          <span>Copy</span>
        </button>
      </div>

      <div className="mt-4 overflow-x-auto">
        <table className="table-auto divide-y">
          <thead className="mb-4">
            <tr className="">
              <th className="font-bold text-left py-4">Claim</th>
              <th className="font-bold text-left py-4">Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(userInfo).map((claimEntry) => {
              const claimName = claimEntry[0];
              const claimValue = claimEntry[1];
              const claimId = `claim-${claimName}`;
              return (
                <tr key={claimName}>
                  <td className="font-bold pr-4 py-2">{claimName}</td>
                  <td id={claimId}>
                    {claimName == 'headers' ? JSON.stringify(claimValue) : claimValue.toString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Profile;
