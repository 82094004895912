import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { QRCodeCanvas } from 'qrcode.react';

import { downloadBlob } from '../../util';

export default function QRCModal({ isOpen, onClose, resourceUrl, title, url }) {
  const [clipboardEnabled, setClipboardEnabled] = useState(false);
  let qrCodeRef = useRef();

  navigator?.permissions
    ?.query({ name: 'clipboard-write' })
    .then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        setClipboardEnabled(true);
      }
    })
    .catch(() => {});

  const onCopy = () => {
    try {
      let qrCanvas = qrCodeRef?.current?.children[0];
      buildQRImage(qrCanvas, (blob) => {
        navigator.clipboard
          .write([new window.ClipboardItem({ [blob.type]: blob })])
          .then(() => onClose());
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onCopyUrl = () => {
    navigator.clipboard.writeText(resourceUrl);
    onClose();
  };

  const wrapText = (context, text, x, y, maxWidth, lineHeight, spacer = ' ') => {
    let lineCount = 1;
    let words = text.split(' ').length > 1 ? text.split(' ') : text.match(/.{1,1}/g);
    let line = '';
    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + spacer;
      let metrics = context.measureText(testLine);
      let testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + spacer;
        y += lineHeight;
        lineCount += 1;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
    return lineCount;
  };

  const buildQRImage = (qrCanvas, callback) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');

    let grayFill = 'rgb(0,0,0,.9)';

    canvas.height = 5000;
    canvas.width = 700;

    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.drawImage(qrCanvas, 10, 10);

    context.font = '24px serif';
    context.fillStyle = 'black';
    let titleLines = wrapText(
      context,
      title,
      qrCanvas.width + 20,
      30,
      700 - 20 - qrCanvas.width,
      24
    );

    context.font = '14px serif';
    context.fillStyle = grayFill;
    let urlLines = wrapText(
      context,
      resourceUrl,
      qrCanvas.width + 20,
      32 + 24 * titleLines,
      700 - 20 - qrCanvas.width,
      16,
      ''
    );

    const tempCanvas = document.createElement('canvas'),
      tCtx = tempCanvas.getContext('2d');

    tempCanvas.height = Math.max(20 + titleLines * 24 + urlLines * 14, qrCanvas.height + 20);
    tempCanvas.width = canvas.width;

    tCtx.drawImage(canvas, 0, 0);

    tempCanvas.toBlob((blob) => {
      callback(blob);
    });
  };

  const onDownload = () => {
    try {
      let qrCanvas = qrCodeRef?.current?.children[0];
      buildQRImage(qrCanvas, (blob) => {
        downloadBlob(blob, 'qrcode.png');
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <div className="bg-white pt-5 pb-4 sm:pb-4 flex justify-center">
                  <div className="flex items-center">
                    <div className="mt-3 text-center sm:mt-0">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        QR Code
                      </Dialog.Title>
                      <div className="mt-2">
                        <QRCodeCanvas value={url} size={192} level={'L'} includeMargin={false} />
                        <div ref={qrCodeRef}>
                          <QRCodeCanvas
                            className="hidden"
                            value={url}
                            size={110}
                            level={'L'}
                            includeMargin={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm focus:outline-none"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-3 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={clipboardEnabled ? onCopy : onDownload}
                  >
                    {clipboardEnabled ? 'Copy Image' : 'Download Image'}
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-3 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onCopyUrl}
                  >
                    Copy Url
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
