import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getCitation } from '../data';

/**
 * Change title of document
 */
export const useTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} | Patient Education`;
    return () => {
      document.title = prevTitle;
    };
  });
};

/**
 * Access citation data by ID
 */
export const useCitation = (citationId) => {
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    async function fetchData() {
      setLoading(!!citationId);
      let data, newLoc;
      if (citationId) {
        [data, newLoc] = await getCitation(citationId);
        if (data) {
          console.info(`Citation id: ${data?.id}, Legacy id: ${data?.record?.legacyId}`);
        }
      }
      setRedirect(newLoc);
      setResult(data || {});
      if (data || (!data && !newLoc)) {
        setLoading(false);
      }
    }
    fetchData();
  }, [citationId]);

  return [result, loading, redirect];
};

/**
 * Access search query params (/search?q=...)
 */
export const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
