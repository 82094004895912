const config = {
  oidc: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    issuer: process.env.REACT_APP_AUTH_ISSUER,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'email', 'groups', 'profile'],
    pkce: true,
    disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false,
  },
  appApi: process.env.REACT_APP_API,
  appUi: process.env.REACT_APP_UI_URL,
};

export default config;
