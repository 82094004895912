/* eslint-disable react/jsx-no-target-blank */
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { globalContext } from '../contexts';
import { downloadQuery, search } from '../data';
import { downloadFile } from '../util';
import { useQueryParams } from '../hooks';
import Chip from './common/Chip';
import LoadingOverlay from './common/LoadingOverlay';
import Sidebar from './Filter/Sidebar';
import ResultsList from './Search/ResultsList';
import SearchBar from './Search/SearchBar';
import { DownloadIcon } from '@heroicons/react/solid';
import FavoritesTable from './common/FavoritesTable';

const Home = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentSearch, setCurrentSearch] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showObsolete, setShowObsolete] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { addHistory, favorites, user } = globalContext();
  const { authState } = useOktaAuth();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  // TODO: Add option to select # per page
  const itemsPerPage = 10;

  /* Effects */
  // Filter changes or show obsolete
  useEffect(() => {
    startSearch(searchValue, false, currentPage, showObsolete, selectedFilters);
  }, [currentSearch, selectedFilters, showObsolete]);

  // Search using url query param if it exists on first load
  useEffect(() => {
    const query = queryParams.get('q');
    if (query) {
      setSearchValue(query);
      const page = queryParams.get('page') ? parseInt(queryParams.get('page'), 10) : 1;
      setCurrentPage(page);
      const obsolete = queryParams.get('obsolete') === 'true';
      setShowObsolete(obsolete);
      const filters = queryParams.get('filters')
        ? JSON.parse(atob(decodeURIComponent(queryParams.get('filters'))))
        : [];
      setSelectedFilters(filters);
    }
  }, []);

  const clearSearch = () => {
    updateUrl();
    setCurrentPage(1);
    setCurrentSearch('');
    setResults();
    setSearchValue('');
    setSelectedFilters([]);
    setShowObsolete(false);
    setShowResults(false);
  };

  // TODO: Add loader
  const startSearch = async (
    value,
    suggestion = false,
    page = 1,
    obsolete = false,
    filters = []
  ) => {
    if (value) {
      addHistory(value);
      setCurrentSearch(value);
      setLoading(true);
      setShowResults(true);
      const config = {
        value,
        filters: selectedFilters.length > 0 ? selectedFilters : filters,
        token: authState?.accessToken?.accessToken,
        noBoost: suggestion,
        start: (page - 1) * itemsPerPage,
        obsolete: showObsolete || obsolete,
      };
      search(config).then((res) => {
        setResults(res);
        setPageCount(Math.ceil(res.hits.found / itemsPerPage));
        setItemOffset((page - 1) * itemsPerPage);
        setLoading(false);
      });
    }
  };

  const handleSubmit = () => {
    if (searchValue !== '') {
      setSelectedFilters([]);
      navigate(`/search?q=${encodeURIComponent(searchValue)}`);
    }
  };

  const handleInput = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterSelect = (data) => {
    let newFilters;
    const isChecked = selectedFilters.some(
      (selected) => JSON.stringify(selected) == JSON.stringify(data)
    );
    if (isChecked) {
      newFilters = selectedFilters.filter(
        (selected) => JSON.stringify(selected) != JSON.stringify(data)
      );
    } else {
      newFilters = selectedFilters.concat(data);
    }

    updateUrl(currentSearch, 1, showObsolete, newFilters);
    setCurrentPage(1);
    setSelectedFilters(newFilters);
  };

  const updateUrl = (value, page, obsolete, filters = []) => {
    if (!value) {
      window.history.replaceState(null, null, `/search`);
    } else if (!page && !obsolete && !filters) {
      window.history.replaceState(null, null, `/search?q=${value}`);
    } else {
      const encoded = encodeURIComponent(btoa(JSON.stringify(filters)));
      window.history.replaceState(
        null,
        null,
        `/search?q=${value}&page=${page || 1}&obsolete=${obsolete || false}&filters=${encoded}`
      );
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (e) => {
    updateUrl(searchValue, e.selected + 1, showObsolete, selectedFilters);
    setCurrentPage(e.selected + 1);
    const length = results.hits.found;
    const newOffset = (e.selected * itemsPerPage) % length;
    console.log(`User requested page number ${e.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    const endOffset = newOffset + itemsPerPage;
    console.log(`Loading items from ${newOffset} to ${endOffset}`);
    const config = {
      value: searchValue,
      start: newOffset,
      filters: selectedFilters,
      token: authState?.accessToken?.accessToken,
      obsolete: showObsolete,
    };
    setLoading(true);
    search(config)
      .then((res) => {
        setResults(res);
        setPageCount(Math.ceil(res.hits.found / itemsPerPage));
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .then(console.log(results));
  };

  // Run a search provided a term, currently used for recent searches and query params
  const searchHandler = (value, suggestion, page, obsolete, filters) => {
    if (value !== '') {
      setSelectedFilters([]);
      navigate(`/search?q=${encodeURIComponent(value)}`);
    }
    setSearchValue(value);
    startSearch(value, suggestion, page, obsolete, filters);
  };

  const favoriteHandler = (favorite) => {
    setSelectedFilters([]);
    navigate(`/citation/${favorite.id}`);
  };

  const handleExport = () => {
    setExporting(true);
    downloadQuery(
      authState?.accessToken?.accessToken,
      searchValue,
      selectedFilters,
      showObsolete
    ).then((file) => {
      downloadFile(file, 'search_export.csv');
      setExporting(false);
    });
  };

  const count = results ? results.hits?.found : 0;
  const endIndex = itemOffset + itemsPerPage > count ? count : itemOffset + itemsPerPage;

  function renderContent() {
    if (!loading) {
      if (!currentSearch) {
        return null;
      }
      if (count == 0) {
        return (
          <div className="mx-auto my-8 text-center text-gray-500">
            <h1 className="text-lg font-medium leading-loose">
              No matching search results for &quot;{currentSearch}&quot;
            </h1>
            <p>Try again using more general search terms</p>
          </div>
        );
      }
    }
    return (
      <div className="md:grid md:grid-cols-4 md:gap-6 md:my-8">
        {exporting && <LoadingOverlay label="Exporting..." />}

        {/* Filters */}
        <div className="border rounded-xl shadow p-5 lg:p-10">
          <Sidebar
            facets={results ? results.facets : {}}
            selectedFilters={selectedFilters}
            handleFilterSelect={handleFilterSelect}
            showObsolete={showObsolete}
            setShowObsolete={setShowObsolete}
            callback={(obs) => {
              updateUrl(currentSearch, 1, obs, selectedFilters);
              setCurrentPage(1);
            }}
          />
        </div>

        {/* Search Results */}
        <div className="flex border rounded-xl shadow p-5 lg:p-10 flex-col md:col-span-3 mt-4 md:mt-0">
          {loading ? (
            <p className="md:text-xl">
              Searching for <span className="font-bold">&quot;{currentSearch}&quot;</span>
            </p>
          ) : (
            <div
              className={`flex justify-between gap-2 ${
                authState.accessToken?.accessToken && '-mb-2'
              }`}
            >
              <p className="md:text-xl">
                Showing <span className="font-bold">{itemOffset + 1}</span> to{' '}
                <span className="font-bold">{endIndex}</span> of{' '}
                <span className="font-bold">{count}</span> results for{' '}
                <span className="font-bold">&quot;{currentSearch}&quot;</span>
              </p>
              {/* Export */}
              {authState.accessToken?.accessToken && (
                <button
                  className="px-4 py-2 text-xs rounded-lg inline-flex items-center flex-none self-start gap-2 border-2 border-gray-300 text-gray-900 hover:text-primary hover:border-primary"
                  onClick={() => handleExport()}
                >
                  <DownloadIcon className="text-primary w-4 h-4" />
                  <span>Export to CSV</span>
                </button>
              )}
            </div>
          )}

          {/* Filter Chips */}
          <div className="flex flex-wrap gap-3 mt-5 mb-6 items-center">
            {selectedFilters.map((filter, i) => (
              <Chip key={i} filter={filter} onClear={handleFilterSelect} />
            ))}
            {selectedFilters.length > 0 && (
              <span
                className="text-sm cursor-pointer text-primary hover:text-primary-3"
                onClick={() => {
                  updateUrl(currentSearch, 1, showObsolete, []);
                  setCurrentPage(1);
                  setSelectedFilters([]);
                }}
              >
                Clear All
              </span>
            )}
          </div>
          <hr />

          <ResultsList results={results} loading={loading} />

          <nav
            className={`flex flex-col md:flex-row md:justify-between ${
              loading ? 'invisible' : 'visible'
            }`}
          >
            <p>
              Showing <span className="font-bold">{itemOffset + 1}</span> to{' '}
              <span className="font-bold">{endIndex}</span> of{' '}
              <span className="font-bold">{count}</span> results
            </p>
            <ReactPaginate
              className="inline-flex my-4 md:my-0"
              pageClassName="px-2 text-primary"
              activeClassName="bg-primary text-white rounded"
              breakLabel="..."
              breakClassName="px-2 text-primary"
              marginPagesDisplayed={2}
              nextClassName="pl-4 text-primary"
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={screen.width >= 768 ? 5 : 2}
              pageCount={pageCount}
              forcePage={pageCount ? itemOffset / itemsPerPage : -1}
              previousClassName="pr-4 text-primary"
              previousLabel="< Previous"
              disabledLinkClassName="hidden"
              renderOnZeroPageCount={null}
            />
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col gap-4 text-gray-700 md:mx-5 ${
        showResults ? 'md:pt-8' : 'md:pt-16'
      }`}
    >
      <SearchBar
        value={searchValue}
        handleInput={handleInput}
        handleSubmit={handleSubmit}
        clearInput={clearSearch}
        loading={loading}
        search={searchHandler}
        searchHistory={user?.searches}
        currentSearch={currentSearch}
        favorites={favorites}
        favoriteHandler={favoriteHandler}
      />
      {showResults ? (
        renderContent()
      ) : (
        <>
          <FavoritesTable />
          <div className="mt-8 md:mt-14 lg:px-16 w-full lg:w-5/6 max-w-screen-lg mx-auto group">
            <h1 className="px-3 mb-2 text-lg font-medium leading-loose">
              Additional Services and Resources
            </h1>
            <ul className="pl-3 mt-2 space-y-1 list-disc list-inside">
              <li>
                <a
                  className="text-primary hover:text-primary-3 hover:cursor-pointer"
                  href="https://jira/servicedesk/customer/portal/56/group/276"
                  target="_blank"
                  rel="noopener"
                >
                  Submit a Patient Education and Communication Service Desk ticket
                </a>
              </li>
              <li>
                <a
                  className="text-primary hover:text-primary-3 hover:cursor-pointer"
                  href="https://child.seattlechildrens.org/people_and_places/departments/interpreter_services/translations/"
                  target="_blank"
                  rel="noopener"
                >
                  Request a Translation
                </a>
              </li>
              <li>
                <a
                  className="text-primary hover:text-primary-3 hover:cursor-pointer"
                  href="https://child.seattlechildrens.org/people_and_places/programs_and_services/patient_education_and_communications/create_materials/"
                  target="_blank"
                  rel="noopener"
                >
                  Create Materials
                </a>
              </li>
              <li>
                <a
                  className="text-primary hover:text-primary-3 hover:cursor-pointer"
                  href="https://child.seattlechildrens.org/people_and_places/programs_and_services/patient_education_and_communications/health_literacy_and_best_practices/"
                  target="_blank"
                  rel="noopener"
                >
                  Learn about health literacy best practices
                </a>
              </li>
            </ul>
          </div>
          <p className="text-center px-4 mt-8">
            If you would like additional help, email{' '}
            <a
              href="mailto:patienteducation@seattlechildrens.org"
              className="text-primary hover:text-primary-3"
            >
              patienteducation@seattlechildrens.org
            </a>
          </p>
        </>
      )}
    </div>
  );
};

export default Home;
