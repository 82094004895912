import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Createable from 'react-select/creatable';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useOktaAuth } from '@okta/okta-react';
import ReactPaginate from 'react-paginate';
import { globalContext } from '../../contexts';
import { FacetLabels } from '../../data/constants';
import Input from '../common/Input';
import ButtonFill from '../common/ButtonFill';
import { updateLookup } from '../../data';
import Modal from '../common/Modal';

const manageLookups = () => {
  const [addingDept, setAddingDept] = useState(false);
  const [addingDeptValue, setAddingDeptValue] = useState();
  const [contact, setContact] = useState();
  const [contacts, setContacts] = useState([]);
  const [editing, setEditing] = useState();
  const [filterValue, setFilterValue] = useState();
  const [newValue, setNewValue] = useState();
  const [pagination, setPagination] = useState({
    itemOffset: 0,
    itemsPerPage: 0,
    currentPage: 0,
    pageCount: 0,
  });
  const [lookups, setLookups] = useState();
  const [selected, setSelected] = useState();
  const [deleting, setDeleting] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const { addDepartment, addJob, departments, facets, updateDepartment } = globalContext();
  const { authState } = useOktaAuth();

  const itemsPerPage = pagination?.itemsPerPage || 10;

  useEffect(() => {
    if (!facets) return;
    setLookups(getLookups());
    setPagination({
      itemOffset: 0,
      itemsPerPage: itemsPerPage,
      currentPage: 0,
      pageCount: Math.ceil((facets[selected]?.length || 0) / itemsPerPage),
    });
  }, [selected]);

  useEffect(() => {
    if (selected) {
      if (filterValue) {
        filter(filterValue);
      } else {
        setLookups(getLookups());
      }
    }
  }, [facets]);

  useEffect(() => {
    if (filterValue) {
      filter(filterValue);
    } else {
      setLookups(getLookups());
    }
    setContacts(
      [
        ...new Set(
          departments
            .map((dept) => dept.frcContact)
            .filter((item) => item)
            .sort()
        ),
      ].map((dept) => ({ label: dept, value: dept }))
    );
  }, [departments]);

  const deleteItem = () => {
    handleSave(deleting, undefined);
    setShowDelete(false);
  };

  const handleDeleteClicked = (item) => {
    setAddingDept(false);
    setShowDelete(true);
    setDeleting(item);
  };

  const handleEditValue = (e) => {
    setNewValue(e.target.value);
  };

  const handleEditClicked = (value) => {
    setAddingDept(false);
    if (selected === 'departments') {
      const contact = departments[departments.findIndex((dept) => dept.name === value)]?.frcContact;
      if (contact) {
        setContact({ label: contact, value: contact });
      } else {
        setContact();
      }
    }
    setEditing(value);
    setNewValue(value);
  };

  const filter = (value, changed = false) => {
    const filtered = getLookups().filter((item) =>
      value ? item.value.toLowerCase().startsWith(value.toLowerCase()) : true
    );
    setPagination({
      ...pagination,
      currentPage: changed || !value ? 0 : pagination.currentPage | 0,
      itemOffset: changed || !value ? 0 : pagination.itemOffset | 0,
      pageCount: Math.ceil((filtered?.length || 0) / itemsPerPage),
    });
    setLookups(filtered);
  };

  const handleFilterInput = (e) => {
    const filterVal = e.target.value;
    setFilterValue(filterVal);
    filter(filterVal, true);
  };

  const handlePageChange = (e) => {
    setEditing();
    setPagination({
      ...pagination,
      currentPage: e.selected,
      itemOffset: pagination.itemsPerPage * e.selected,
    });
  };

  const handleSave = (item, newValue) => {
    setEditing();
    if (selected === 'departments') {
      updateDepartment(item.value, newValue, contact?.value);
    }
    setLookups(
      lookups
        .map((lookup) =>
          lookup.value === item.value
            ? { ...lookup, updating: true, value: newValue || item.value }
            : lookup
        )
        .filter((lookup) => lookup)
    );
    updateLookup(authState?.accessToken?.accessToken, [
      {
        type: selected,
        oldValue: item.value,
        newValue,
        contact: contact?.value,
      },
    ]).then((res) => addJob(res));
    setContact();
  };

  const handleSelect = (val) => {
    const category = val.value;
    setFilterValue();
    setSelected(category);
  };

  const getLookups = () =>
    selected === 'departments'
      ? departments.map((dept) => ({ value: dept.name }))
      : (facets || [])[selected];

  return (
    <div className="mx-2 md:mx-24 my-4 md:my-16 max-w-screen-lg">
      <article className="pb-8 leading-relaxed border-b-2">
        <header className="font-medium text-2xl mb-3 text-gray-900">Manage Lookups</header>
        <div className="flex items-center pt-24">
          <label htmlFor="category" className="w-40">
            Select Category:
          </label>
          <Select
            className="rounded w-112 shrink-0"
            name="category"
            options={
              facets
                ? Object.keys(facets)
                    .map((cat) => ({ label: FacetLabels[cat], value: cat }))
                    .sort((a, b) => a.label > b.label)
                : []
            }
            onChange={handleSelect}
            styles={customStyles}
            error={undefined}
            isLoading={!facets}
            isDisabled={!facets}
          />
        </div>
      </article>
      {lookups && (
        <>
          <div className="flex mt-8 justify-between align-middle">
            <Input
              className="p-2"
              label="Filter Values"
              name="filter"
              error={undefined}
              onChange={handleFilterInput}
              value={filterValue || ''}
            />
            {!addingDept && selected === 'departments' && (
              <span
                className="p-2 cursor-pointer text-gray-600 hover:text-primary"
                onClick={() => {
                  setEditing(false);
                  setAddingDept(true);
                  setContact();
                }}
              >
                Add Department
              </span>
            )}
          </div>
          {addingDept && selected === 'departments' && (
            <div className="flex mt-8 align-middle p-6 bg-gray-50 border border-gray-200">
              <div className="flex flex-col">
                <Input
                  className="p-2 flex-grow"
                  label="Department Name"
                  name="departmentName"
                  error={undefined}
                  onChange={(e) => setAddingDeptValue(e.target.value)}
                  value={addingDeptValue || ''}
                  grow
                />
                <div className="flex flex-row mt-2">
                  <label htmlFor="contact" className="w-40">
                    Contact
                  </label>
                  <Createable
                    className="rounded w-112 shrink-0"
                    name="contact"
                    isClearable
                    isSearchable
                    label="Contact"
                    options={contacts}
                    onChange={(val) => setContact(val)}
                    styles={customStyles}
                    error={undefined}
                    value={contact}
                    menuPlacement="auto"
                  />
                </div>
              </div>
              <ButtonFill
                className="ml-4 max-h-10"
                text="Save"
                secondary
                disabled={(addingDeptValue || '').length === 0}
                onButtonClick={() => {
                  const deptName = addingDeptValue;
                  addDepartment(deptName, contact?.value);
                  setAddingDept(false);
                  setAddingDeptValue();
                }}
              />
              <button
                className="ml-4 max-h-10 px-4 py-2 text-sm rounded-lg border-2 border-gray-300 text-gray-900 hover:text-white hover:border-secondary hover:bg-secondary mr-4"
                onClick={() => {
                  setAddingDept(false);
                  setAddingDeptValue();
                  setContact();
                }}
              >
                Cancel
              </button>
            </div>
          )}
          <div className="flex flex-col mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block sm:px-6 lg:px-8 w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-full"
                        >
                          Lookup Value
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {lookups &&
                        lookups
                          .slice(
                            pagination?.itemOffset,
                            pagination?.itemOffset + pagination?.itemsPerPage
                          )
                          .map((item) => (
                            <tr key={item.value} className={`${item.updating ? 'bg-gray-50' : ''}`}>
                              <td className="px-2 md:px-6 py-2 whitespace-nowrap w-32">
                                {editing === item.value ? (
                                  <>
                                    <Input
                                      className="p-2 flex-grow"
                                      value={newValue}
                                      onChange={handleEditValue}
                                    />
                                    {selected === 'departments' && (
                                      <div className="flex items-center pt-2">
                                        <label htmlFor="contact" className="w-20">
                                          Contact:
                                        </label>
                                        <Createable
                                          className="rounded w-112 shrink-0"
                                          name="contact"
                                          isClearable
                                          isSearchable
                                          label="Contact"
                                          options={contacts}
                                          onChange={(val) => setContact(val)}
                                          styles={customStyles}
                                          error={undefined}
                                          value={contact}
                                          menuPlacement="auto"
                                          menuPortalTarget={document.body}
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="text-gray-900">
                                      {item.value || 'N/A'}
                                      <span className="ml-2 text-gray-400 text-sm italic">
                                        {item.count?.toLocaleString('en-US')}
                                      </span>
                                    </div>
                                    {selected === 'departments' && (
                                      <span className="text-gray-500 italic">
                                        {departments[
                                          departments.findIndex((dept) => dept.name === item.value)
                                        ]?.frcContact || ''}
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>

                              <td className="flex flex-row justify-end px-6 py-6 whitespace-nowrap">
                                {editing === item.value ? (
                                  <>
                                    <button
                                      className="px-4 py-2 text-sm rounded-lg border-2 border-gray-300 text-gray-900 hover:text-white hover:border-secondary hover:bg-secondary mr-4"
                                      onClick={() => {
                                        setEditing();
                                        setContact();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <ButtonFill
                                      text="Save Changes"
                                      onButtonClick={() => handleSave(item, newValue)}
                                    />
                                  </>
                                ) : item.updating ? (
                                  <div className="flex items-center">
                                    <svg
                                      className="animate-spin h-6 w-6 text-primary"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                    <h2 className="text-gray-500 text-sm ml-2">Updating...</h2>
                                  </div>
                                ) : (
                                  <>
                                    <PencilIcon
                                      className="h-6 self-center text-primary cursor-pointer mr-4"
                                      onClick={() => handleEditClicked(item.value)}
                                    />

                                    <TrashIcon
                                      className="h-6 self-center text-secondary cursor-pointer"
                                      onClick={() => handleDeleteClicked(item)}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <nav className={`flex flex-col md:flex-row md:justify-between m-2 mt-4`}>
              {lookups.length > 0 && (
                <p>
                  Showing{' '}
                  <span className="font-bold">
                    {Math.min(pagination?.itemOffset + 1, lookups.length)}
                  </span>{' '}
                  to{' '}
                  <span className="font-bold">
                    {Math.min(pagination?.itemOffset + pagination?.itemsPerPage, lookups.length)}
                  </span>{' '}
                  of <span className="font-bold">{lookups.length}</span> lookups
                </p>
              )}
              <ReactPaginate
                className="inline-flex my-4 md:my-0"
                pageClassName="px-2 text-primary"
                activeClassName="bg-primary text-white rounded"
                breakLabel="..."
                breakClassName="px-2 text-primary"
                forcePage={pagination.currentPage}
                marginPagesDisplayed={2}
                nextClassName="pl-4 text-primary"
                nextLabel="Next >"
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                pageCount={pagination.pageCount}
                previousClassName="pr-4 text-primary"
                previousLabel="< Previous"
                disabledLinkClassName="hidden"
                renderOnZeroPageCount={emptyResults}
              />
            </nav>
          </div>
        </>
      )}
      {/* Delete modal */}
      <Modal
        headline="Delete Item"
        text="Are you sure you want to delete this item?"
        cancel={() => setShowDelete(false)}
        cta="Delete"
        isOpen={showDelete}
        accept={deleteItem}
        secondary
      />
    </div>
  );
};

const emptyResults = () => <span>No results found.</span>;

/**
 * Define custom styles for select components.
 */
const customStyles = {
  input: (provided) => ({
    ...provided,
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  // border-secondary if error, border-gray-300 otherwise
  control: (provided, state) => ({
    ...provided,
    border: state.selectProps.error ? '1px solid #db5a1d' : '1px solid #d1d5db',
  }),
};

export default manageLookups;
