import React, { useContext, useState, useEffect, createContext } from 'react';
import {
  getDepartments,
  getFacets,
  getJobs,
  getUser,
  postDepartment,
  updateUserSession,
} from '../data';
import { useOktaAuth } from '@okta/okta-react';
import config from '../config';

const GlobalContext = createContext();
const HISTORY_MAX = 10;
const FACET_INTERVAL = 10 * 60; // 10 minutes
const SYNC_INTERVAL = 15; // 15 seconds

function GlobalProvider({ children }) {
  const { authState, oktaAuth } = useOktaAuth();

  // Initialize facet state
  const [batchJobs, setBatchJobs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [facets, setFacets] = useState({});
  const [facetDataStale, setFacetDataStale] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [oktaUser, setOktaUser] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [userDataStale, setUserDataStale] = useState(true);

  /**
   * When the global context is loaded, set the data to stale (thereby triggering
   * a refresh) every N seconds defined by SYNC_INTERVAL.
   */
  useEffect(() => {
    const facetsSyncInterval = setInterval(() => setFacetDataStale(true), FACET_INTERVAL * 1000);
    const userSyncInterval = setInterval(() => setUserDataStale(true), SYNC_INTERVAL * 1000);

    window.addEventListener('storage', onStorageUpdate);

    return () => {
      clearInterval(facetsSyncInterval);
      clearInterval(userSyncInterval);
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  /**
   * When there is a backend job to update or remove a facet (also known as a
   * lookup), update facets to reflect that immediately.
   */
  useEffect(() => {
    if (!facets) return;
    setFacets(
      Object.keys(facets).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: Object.values(
            facets[curr]
              .map((facet) => getUpdatedValue(curr, facet))
              .filter((e) => e)
              .reduce((prev, { count, updating, value }) => {
                prev[value] = prev[value] || { count, updating, value };
                if (prev[value].count !== count) prev[value].count += count;
                return prev;
              }, {})
          ),
        }),
        {}
      )
    );
    batchJobs
      .filter((batch) => batch?.config)
      .forEach((job) => {
        if (job.config?.contact) {
          updateDepartment(config.oldValue, config.contact);
        }
      });
  }, [batchJobs]);

  /**
   * When the access token value changes (meaning the user logged in or out), we
   * need to refresh the global context because it can change what data is
   * available to the user.
   */
  useEffect(() => {
    setUserDataStale(true);
    if (authState?.accessToken?.accessToken) {
      const adminGroup = 'appRlePatient-Education_Admins';
      setUser({ ...user, admin: authState?.accessToken?.claims?.groups?.includes(adminGroup) });
      setFacetDataStale(true);
    } else {
      setFacets();
      setUser();
      setOrder();
    }
  }, [authState?.accessToken?.accessToken]);

  useEffect(() => {
    if (!facetDataStale) {
      return;
    }
    const token = authState?.accessToken?.accessToken;
    if (token) {
      setIsLoading(true);
      getFacets(token).then((res) => {
        setFacets(res);
        setIsLoading(false);
      });
    }
    setFacetDataStale(false);
  }, [facetDataStale]);

  /**
   * When the 'useDataStale' state changes, we need to reload all the global state context,
   * including facets & user data.
   */
  useEffect(() => {
    if (!userDataStale) {
      return;
    }
    const tasks = [];
    const token = authState?.accessToken?.accessToken;
    if (token) {
      tasks.push(
        ...[
          getUser(token).then((res) => {
            setUser(res);
            if (res?.admin) {
              getDepartments(token).then((res) => setDepartments(res));
            }
          }),
          oktaAuth.getUser().then((res) => setOktaUser(res)),
          getJobs(token).then((res) => {
            if (res) {
              setBatchJobs(res);
            }
          }),
        ]
      );
    } else {
      setDepartments([]);
      setOktaUser(undefined);
    }
    Promise.all(tasks).then(() => {
      setUserDataStale(false);
    });
  }, [userDataStale]);

  /**
   * Add a new department to list of existing departments
   * @param {string} department - department name
   */
  const addDepartment = (department, contact) => {
    setDepartments(
      [...departments, { name: department, frcContact: contact }].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    );
    const token = authState?.accessToken?.accessToken;
    if (token && department) {
      postDepartment(token, department, contact).then((res) => setDepartments(res));
    }
  };

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === 'session') {
      setUser({
        ...(user || {}),
        session: JSON.parse(newValue),
      });
    }
  };

  /**
   * Add a new batch job to list of existing jobs
   * @param {obj} job - batch job to add to batch jobs list
   * @returns complete list of batch jobs
   */
  const addJob = (job) => setBatchJobs([...batchJobs, job]);

  /**
   * Add a search tearm to the user's search history
   * @param {string} newItem - search term
   */
  const addHistory = (newItem) => {
    const history = user?.searches || [];
    if (!history.includes(newItem)) {
      history.unshift(newItem);
    } else {
      const oldIdx = history.indexOf(newItem);
      history.splice(0, 0, history.splice(oldIdx, 1)[0]);
    }
    history.length = Math.min(history.length, HISTORY_MAX);
    setUser({
      ...user,
      searches: history,
    });
  };

  /**
   * Update the departments list to reflect a user update
   * @param {string} oldName - current department name
   * @param {string} newName - new department name
   * @param {string} contact - name of the contact responsible for the department
   */
  const updateDepartment = (oldName, newName, contact) =>
    setDepartments(
      departments
        .filter((dept) => dept.name !== oldName || newName)
        .map((dept) => {
          if (dept.name === oldName) {
            return {
              ...dept,
              name: newName,
              frcContact: contact,
            };
          }
          return dept;
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    );

  /**
   * Used to set the facets as stale after a delay, useful when citations
   * are added or edited with new values
   * @param {int} delay - delay in seconds
   */
  const updateFacets = (delay) =>
    setTimeout(() => {
      setFacetDataStale(true);
    }, delay * 1000);

  /**
   * Update the user's session on the backend
   * @param {obj} session - user session
   */
  const setOrder = (order) => {
    const session = {
      ...user?.session,
      order,
    };
    const token = authState?.accessToken?.accessToken;
    if (token) {
      updateUserSession(token, session);
    }
    setUser({
      ...(user || {}),
      session: session,
    });
    localStorage.setItem('session', JSON.stringify(session));
  };

  const setFavorites = (favorites) => {
    const session = {
      ...user?.session,
      favorites: favorites.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0)),
    };
    const token = authState?.accessToken?.accessToken;
    if (token) {
      updateUserSession(token, session);
    }
    setUser({
      ...(user || {}),
      session: session,
    });
    localStorage.setItem('session', JSON.stringify(session));
  };

  /**
   * Update a facet object with batch jobs that are pending on the backend
   * @param {string} category - facet category being updated
   * @param {obj} facet - facet being updated
   * @returns facet with updates from pending batch jobs
   */
  const getUpdatedValue = (category, facet) => {
    const update = batchJobs
      .filter((batch) => batch?.config)
      .reduce(
        (prev, curr) => [
          ...prev,
          ...curr.config.map((update) => ({
            ...update,
            updating: !curr.complete,
            start: curr.start,
          })),
        ],
        []
      )
      .sort((a, b) => {
        if (a.start > b.start) return -1;
        if (a.start < b.start) return 1;
        return 0;
      })
      .find(
        (update) =>
          update.type === category &&
          (update.oldValue === facet.value || update.newValue === facet.value)
      );

    if (!update) {
      return facet;
    }

    return update.newValue
      ? { ...facet, value: update.newValue, updating: update.updating }
      : undefined;
  };

  return (
    <GlobalContext.Provider
      value={{
        addDepartment,
        addJob,
        addHistory,
        departments,
        facets,
        favorites: user?.session?.favorites || [],
        isLoading,
        oktaUser,
        order: user?.session?.order || [],
        setFavorites,
        setOrder,
        updateDepartment,
        updateFacets,
        user,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;

// Create a hook to use the FacetContext
export function globalContext() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
