import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { globalContext } from '../contexts';
import Input from './common/Input';
import ButtonFill from './common/ButtonFill';
import Modal from './common/Modal';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from './common/LoadingOverlay';
import { submitOrder } from '../data';
import { useOktaAuth } from '@okta/okta-react';

const Order = () => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { oktaUser, order, setOrder } = globalContext();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  const handleRemoveItem = (e, item) => {
    e.preventDefault();
    setOrder(order.filter((orderItem) => orderItem !== item));
  };

  const updateQuantity = (e, item) => {
    e.preventDefault();
    setOrder(
      order.map((orderItem) =>
        orderItem === item ? { ...orderItem, quantity: Math.max(e.target.value, 1) } : orderItem
      )
    );
  };

  const onSubmit = async (data) => {
    setLoading(true);
    data.orders = order;
    console.log(data);
    await submitOrder(authState?.accessToken?.accessToken, data);
    setLoading(false);
    setShowSuccess(true);
  };

  const returnToView = () => {
    setShowSuccess(false);
    setOrder([]);
    navigate(`/`);
  };

  return (
    <>
      {(!oktaUser || loading) && <LoadingOverlay />}
      <div className="mx-2 md:mx-24 my-4 md:my-16 max-w-screen-lg">
        <article className="pb-8 leading-relaxed border-b-2">
          <header className="font-medium text-2xl mb-3 text-gray-900">
            Order Seattle Children&apos;s Education Materials
          </header>
          <p className="pt-4 w-5/6">
            Use this form to order multiple copies of Children&apos;s-produced educational materials
            from the Copy Center.
          </p>
          <p className="pt-4 w-5/6">
            The Copy Center can print small or large quantities of materials and deliver them to any
            Children&apos;s mailbox. Please consider ordering smaller quantities to reduce inventory
            and waste.
          </p>
          <p className="pt-4 w-5/6">
            All items are sent to you via interoffice mail from the Copy Center.
          </p>
          <p className="pt-4 w-5/6">
            <span className="font-bold">Note:</span> Unless &quot;color&quot; is indicated in
            Printing Specs, materials are printed in black and white. If you have questions about a
            material&apos;s printing specs, email{' '}
            <a
              href="mailto:patienteducation@seattlechildrens.org"
              className="text-primary hover:text-primary-3"
            >
              patienteducation@seattlechildrens.org
            </a>
            .
          </p>
        </article>
        <form className="flex flex-col gap-8 mb-14">
          <h1 className="text-xl font-medium mb-4 mt-8">Materials</h1>
          {order?.length === 0 && (
            <span className="italic">
              No materials selected - Please add some materials to the order.
            </span>
          )}
          {order?.length > 0 && (
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block sm:px-6 lg:px-8 w-full">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Item Number
                          </th>
                          <th
                            scope="col"
                            className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Language
                          </th>
                          <th
                            scope="col"
                            className="px-2 md:px-6 py-1 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Quantity
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Remove</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {order.map((item, idx) => (
                          <tr key={idx}>
                            <td className="px-2 md:px-6 py-2 whitespace-nowrap w-32">
                              <div className="text-gray-900">{item.itemNum || 'N/A'}</div>
                            </td>
                            <td className="px-2 md:px-6 py-2 w-full">
                              <div className="text-primary">
                                <a
                                  href={
                                    item.readableId
                                      ? `/citation/${item.readableId}`
                                      : `/citation/${item.id}`
                                  }
                                >
                                  {item.title}
                                </a>
                              </div>
                              {item.printingSpecs && (
                                <div className="text-gray-500 italic">
                                  <span className="font-medium">Printing Specs:</span>{' '}
                                  {item.printingSpecs}
                                </div>
                              )}
                            </td>
                            <td className="px-2 md:px-6 py-2 w-full">
                              <div className="text-gray-900">{item.lang}</div>
                            </td>
                            <td className="px-6 py-6 whitespace-nowrap">
                              <input
                                className="text-gray-900 border border-gray-200 rounded p-2 w-20"
                                type="number"
                                name="quantity"
                                value={item.quantity}
                                onChange={(e) => updateQuantity(e, item)}
                              />
                            </td>
                            <td className="px-6 py-6 whitespace-nowrap text-sm font-medium w-20">
                              <TrashIcon
                                className="h-6 self-center text-secondary cursor-pointer"
                                onClick={(e) => handleRemoveItem(e, item)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr />

          <h1 className="text-xl font-medium mb-4 mt-8">Contact Information</h1>

          <Input
            label="First Name:"
            type="text"
            defaultValue={oktaUser?.given_name || ''}
            required
            className={errors.firstName ? 'border-secondary' : 'border-gray-300'}
            {...register('firstName', { required: true })}
          />
          <Input
            label="Last Name:"
            type="text"
            defaultValue={oktaUser?.family_name || ''}
            required
            className={errors.lastName ? 'border-secondary' : 'border-gray-300'}
            {...register('lastName', { required: true })}
          />
          <Input
            label="Email:"
            type="text"
            defaultValue={oktaUser?.email?.toLowerCase() || ''}
            required
            className={errors.email ? 'border-secondary' : 'border-gray-300'}
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          <Input
            label="Phone Number:"
            type="text"
            required
            className={errors.phoneNumber ? 'border-secondary' : 'border-gray-300'}
            {...register('phoneNumber', { required: true })}
          />
          <Input
            label="Department:"
            type="text"
            required
            className={errors.department ? 'border-secondary' : 'border-gray-300'}
            {...register('department', { required: true })}
          />
          <Input
            label="Mail Stop:"
            type="text"
            required
            className={errors.mailStop ? 'border-secondary' : 'border-gray-300'}
            {...register('mailStop', { required: true })}
          />
          <Input
            label="Date of Order:"
            type="date"
            className={`${
              errors.dateOfOrder ? `border-secondary` : `border-gray-300`
            } text-gray-400 pointer-events-none`}
            readOnly={true}
            value={new Date().toISOString().split('T')[0]}
            required
            tabIndex={-1}
            {...register('dateOfOrder', { required: true })}
          />
          <Input
            label="Date Required:"
            type="date"
            required
            className={errors.dateRequired ? 'border-secondary' : 'border-gray-300'}
            {...register('dateRequired', { required: true })}
          />
          <hr />

          <h1 className="text-xl font-medium mt-8">Billing</h1>
          <p className="w-5/6">
            Printing costs will be charged to your department budget. To estimate the cost of your
            order, please use these approximate costs.
          </p>
          <p className="w-5/6">Black and white letter and legal = $.07 per page</p>
          <p className="w-5/6">Color letter and legal = $.17 per page</p>
          <p className="w-5/6">
            If you have questions about a material&apos;s cost, email{' '}
            <a
              href="mailto:patienteducation@seattlechildrens.org"
              className="text-primary hover:text-primary-3"
            >
              patienteducation@seattlechildrens.org
            </a>
            .
          </p>

          <Input
            label="Cost Center:"
            type="text"
            required
            className={errors.costCenter ? 'border-secondary' : 'border-gray-300'}
            {...register('costCenter', { required: true })}
          />
          <Input
            label="Activity:"
            type="text"
            className={errors.activity ? 'border-secondary' : 'border-gray-300'}
            {...register('activity', { required: false })}
          />
          <Input
            label="Cost Category:"
            type="text"
            className={errors.costCategory ? 'border-secondary' : 'border-gray-300'}
            {...register('costCategory', { required: false })}
          />
          <hr />
          <h1 className="text-xl font-medium mt-8">Special Instructions</h1>
          <div>
            <ul className="list-disc pl-6 pt-1 space-y-4 w-5/6">
              <li>
                <span className="font-bold">Note:</span> Unless &quot;color&quot; is indicated in
                Printing Specs, materials are printed in black and white. If you have questions
                about a material&apos;s printing specs, email{' '}
                <a
                  href="mailto:patienteducation@seattlechildrens.org"
                  className="text-primary hover:text-primary-3"
                >
                  patienteducation@seattlechildrens.org
                </a>
                .
              </li>
              <li>Shipments to other locations or rush orders can be requested below.</li>
            </ul>
          </div>
          <textarea
            className="rounded border border-gray-300 w-3/4 shrink-0 h-32 max-h-96"
            {...register('specialInstructions')}
          />
        </form>
        <ButtonFill
          text="Submit Order"
          type="submit"
          disabled={order.length === 0}
          onButtonClick={handleSubmit(onSubmit)}
        />
      </div>
      {/* Success modal */}
      <Modal
        headline="Success!"
        text={`Your order has been submitted.`}
        cta="Ok. Return to home."
        isOpen={showSuccess}
        accept={returnToView}
      />
    </>
  );
};

export default Order;
